import React from 'react';
import AlertProvider from './util/Alert';
import CountryStateCityProvider from './Common/CountyStateCity';
import NotificationProvider from './Common/Notification';

import UserProvider from './Common/User';
import CommonJobsProvider from './Common/Jobs';

import ConnectionProviderV from './Vendor/Connections';

import TimesheetProvider from './Timesheet.provider';

import JobProviderV from './Vendor/Jobs';
import JobsProviderC from './Client/Jobs';
import JobProviderCt from './Consultant/Jobs';

import InvoiceProviderV from './Vendor/invoice';
import InvoiceProviderC from './Client/invoice';

import ProjectProviderC from './Client/Project';
import ProjectProviderV from './Vendor/Project';

import VendorProviderC from './Client/Vendor';

import CountProvider from './Common/Count';
import JobProvider from './Job.provider';
import { useSelector } from 'react-redux';
import { selectUserDetails } from 'Redux/Feature/user';
import { store } from 'Redux/Store';

export default function ContextProviders({ children }) {
  const user = useSelector(selectUserDetails);
  const userId = user?.user_id;

  return (
    <CountryStateCityProvider>
      <NotificationProvider userId={userId}>
        <AlertProvider>
          <UserProvider>
            <TimesheetProvider userId={userId}>
              <CommonJobsProvider>
                <JobProvider>
                  {/* Client Providers */}
                  <ProjectProviderC userId={userId}>
                    <VendorProviderC userId={userId}>
                      <JobsProviderC userId={userId}>
                        <InvoiceProviderC userId={userId}>
                          <CountProvider userId={userId}>
                            {/* Vendor Providers */}
                            <ConnectionProviderV userId={userId}>
                              <ProjectProviderV userId={userId}>
                                <JobProviderV userId={userId}>
                                  <InvoiceProviderV userId={userId}>
                                    {/* Consultant Providers */}
                                    <JobProviderCt userId={userId}>
                                      {children}
                                    </JobProviderCt>
                                  </InvoiceProviderV>
                                </JobProviderV>
                              </ProjectProviderV>
                            </ConnectionProviderV>
                          </CountProvider>
                        </InvoiceProviderC>
                      </JobsProviderC>
                    </VendorProviderC>
                  </ProjectProviderC>
                </JobProvider>
              </CommonJobsProvider>
            </TimesheetProvider>
          </UserProvider>
        </AlertProvider>
      </NotificationProvider>
    </CountryStateCityProvider>
  );
}
