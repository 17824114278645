import React, { useState, createContext, useContext } from "react";
import Swal from "sweetalert2";
import api, { apiV2 } from "../../Utils/api";
import { roles } from "../../Utils/constants";

const apiPath = {
  fetchCurrentClients: "/user/listcvmusers",
  fetchRequestedClients: "/user/invitescvm",
  requestNewClient: "/user/requestcvm",
  acceptRequestFromClient: "/user/acceptinvite",
  declineRequestFromClient: "/user/declineinvite",
  fetchV2Vendors: "/vendors",
  fetchV2Clients: "/clients",
  fetchV2Resources: "/resources",
  fetchCurrentVendors: "/user/listvvmusers",
  fetchRequestedVendors: "/user/invitesvvm",
  requestNewVendor: "/user/requestvvm",
  acceptRequestFromVendor: "/user/acceptinvitevendor",
  declineRequestFromVendor: "/user/declineinvitevendor",
  fetchCurrentConsultants: "/user/listvcmusers",
  acceptRequestFromConsultant: "/user/acceptinvitevcm",
  declineRequestFromConsultant: "/user/declineinvitevcm",
  requestNewConsultant: "/user/requestvcm",
  fetchClientsWithConsultantCount: "/user/getclientswithconsultantcount",
  fetchVendorsWithConsultantCount: "/user/getvendorswithconsultantcount",
  fetchConsultantsWithJob: "/user/getconsultants",
  inviteNewUser: "/invite/createinvite",
  fetchInvitedUsers: "/user/invitedusers",
  vendorImport: "/vendorimport/upload"
};

const nullFn = () => null;
const ConnectionContext = createContext({
  loading: false,
  clients: [],
  vendors: [],
  invitedVendors: [],
  consultants: [],
  clientsWithCount: [],
  vendorsWithCount: [],
  fetchClients: (userRole) => null,
  fetchClientsList: (param1, param2) => null,
  fetchCurrentClients: nullFn,
  sendRequestToClient: nullFn,
  acceptRequestFromClient: nullFn,
  declineRequestFromClient: nullFn,
  fetchCurrentVendorsForClients: (param1, param2, param3, param4) => null,
  fetchCurrentVendors: (param1, param2, param3, param4, param5) => null,
  fetchInvitedVendors: (param1, param2, param3, param4) => null,
  sendRequestToVendor: nullFn,
  acceptRequestFromVendor: param => null,
  declineRequestFromVendor: param => null,
  fetchCurrentConsultants: nullFn,
  fetchCurrentConsultantsListforJob: param => null,
  sendRequestToConsultant: nullFn,
  fetchClientsWithConsultantCount: nullFn,
  fetchVendorsWithConsultantCount: nullFn,
  fetchConsultantsWithJob: nullFn,
  inviteNewUser: nullFn,
  acceptRequestFromConsultant: nullFn,
  declineRequestFromConsultant: nullFn,
  importVendor: (body, type) => null
});

export const useConnectionProvider = () => useContext(ConnectionContext);

function unionBy(...arrays) {
  const iteratee = arrays.pop();

  if (Array.isArray(iteratee)) {
    return []; // return empty if iteratee is missing
  }

  return [...arrays].flat().filter(
    (
      (set) => (o) =>
        set.has(iteratee(o)) ? false : set.add(iteratee(o))
    )(new Set())
  );
}

const ConnectionProvider = ({ children, userId }) => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientsWithCount, setClientsWithCount] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [invitedVendors, setInvitedVendors] = useState([]);
  const [vendorsWithCount, setVendorsWithCount] = useState([]);
  const [consultants, setConsultants] = useState([]);

  const fetchClientsList = async (pagination, setRowCount) => {
    setLoading(true);
    try {
      const params = { page: pagination.pageIndex + 1, size: pagination.pageSize };
      const data = [];
      // const current = await api.get(apiPath.fetchCurrentClients, { params });
      const current = await apiV2.get(apiPath.fetchV2Clients, {params});
      
      if (current?.data?.status === "SUCCESS") {
        setRowCount(10 * current.data?.data?.meta?.pages);
        const result = current.data?.data?.result?.length ? current?.data?.data?.result : [];
        data.push(
          ...result.map((item) => {
            return {
              id: item?.id,
              firstName: item.client.firstName,
              lastName: item.client.lastName,
              status: item?.isApproved ? "Current": "Requested",
              requestFrom: item?.requestFrom,
              companyName: item.client.company,
              profilePhoto: item?.client.profilePhoto,
              username: item?.client.username
            }
          })
        );
      }
      setClients(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchClients = async (userRole) => {
    setLoading(true);
    try {
      const params = { id: userId, for: userRole, size: 50 };
      const data = [];
      const current = await api.get(apiPath.fetchCurrentClients, { params });
      // const current = await apiV2.get(apiPath.fetchV2Clients);
      // const requests = await api.get(apiPath.fetchRequestedClients, { params });
      const invited = await api.get(apiPath.fetchInvitedUsers, {
        params: { userId, userType: roles.client },
      });
      
      if (current?.data?.success) {
        const result = current.data?.result?.length ? current?.data?.result : [];
        data.push(
          ...result.map((item) => {
            return {
              id: item?.id,
              firstName: item?.firstName,
              lastName: item?.lastName,
              status: "Current",
              companyName: item?.company,
              profilePhoto: item?.profilePhoto,
              username: item?.username
            }
          })
        );
      }

      // if (requests?.data?.success) {
      //   const { request, user } = requests.data.result?.request?.length
      //     ? requests.data.result
      //     : { request: [], user: [] };

      //   data.push(
      //     ...request.map((item, index) => ({
      //       id: item.id,
      //       firstName: user[index].firstName,
      //       lastName: user[index].lastName,
      //       // WHY ARE WE MANAGING TEH STATUSED
      //       status: "Requested",
      //       from: item.requestFrom,
      //       fromid: user[index].id,
      //       companyName: user[index].company,
      //       username: item?.username,
      //       profilePhoto: user[index]?.profilePhoto,
      //     }))
      //   );
      // }
      if (invited?.data?.success) {
        const result = invited.data.invite?.length ? invited.data.invite : [];
        data.push(
          ...result.map((item) => ({
            id: item.id,
            firstName: item.name,
            lastName: "",
            status: "Invited",
            companyName: item.company,
            profilePhoto: item?.profilePhoto,
            email: item.email,
            username: item?.username
          }))
        );
      }
      setClients(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentClients = async () => {
    setLoading(true);
    const params = { id: userId, for: "vendor" };

    try {
      const current = await api.get(apiPath.fetchCurrentClients, { params });
      if (current?.data?.success) {
        const result = (
          current.data.result?.length ? current.data.result : []
        ).map((item) => ({
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          status: "Current",
          username: item?.username,
          companyName: item.company,
        }));
        setClients(unionBy(clients, result, (item) => item.id));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const sendRequestToClient = async ({ username, email }) => {
    setLoading(true);
    try {
      const body = { id: userId, username, email, requestFrom: "VENDOR" };

      const res = await api.post(apiPath.requestNewClient, body);
      if (res?.data?.success) {
        const { CVM, user } = res.data;
        setClients([
          ...clients,
          {
            id: CVM.id,
            firstName: user.firstName,
            lastName: user.lastName,
            status: "Requested",
            from: CVM.requestFrom,
          },
        ]);

        setLoading(false);
        Swal.fire({
          title: "Success",
          text: "Request sent successfully",
          icon: "success",
        });
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      if (e?.response?.status >= 400 && e?.response?.status <= 500) {
        Swal.fire({
          title: "Error",
          text: e.response.data.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      }
    }
  };

  const acceptRequestFromClient = async (id, isVendorRequest = false) => {
    try {
      const res = await api.put(apiPath.acceptRequestFromClient, { id });
      if (res?.data?.success) {
        const temp = [...(isVendorRequest ? vendors : clients)];
        const index = temp.findIndex((item) => item.id === id);
        temp[index].status = "Current";
        setClients(temp);

        Swal.fire({
          title: "Success",
          text: "Request accepted successfully",
          icon: "success",
        });
      }
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  const declineRequestFromClient = async (id, isVendorRequest = false) => {
    try {
      const res = await api.delete(apiPath.declineRequestFromClient, {
        params: { id },
      });

      if (res?.data?.success) {
        const temp = [...(isVendorRequest ? vendors : clients)];
        const index = temp.findIndex((item) => item.id === id);
        temp.splice(index, 1);
        setClients(temp);

        Swal.fire({
          title: "Success",
          text: "Request declined successfully",
          icon: "success",
        });
      }
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  const fetchCurrentVendorsForClients = async (pagination, sorting, rowCount, setRowCount) => {
    setLoading(true);
    try {
      // const params = { id: userId };
      const current = await apiV2.get(apiPath.fetchV2Vendors, { params: {page: pagination.pageIndex + 1, size: pagination.pageSize} });
      // const requests = await api.get(apiPath.fetchRequestedVendors, { params });
      const data = [];
      
      if (current?.data?.status === "SUCCESS") {
        setRowCount(10 * current.data?.data?.meta?.pages)
        const result = (
          current.data?.data?.result?.length ? current.data?.data?.result : []
        ).map((mapping) => {
            return {
              id: mapping?.id,
              vendor_id: mapping?.vendor?.id,
              firstName: mapping?.vendor?.firstName,
              lastName: mapping?.vendor?.lastName,
              status: mapping?.isApproved ? "Current" : "Requested",
              isShowApproveReject: mapping?.isShowApproveReject,
              isApproved: mapping?.isApproved,
              requestFrom: mapping?.requestFrom,
              username: mapping?.vendor?.username,
              companyName: mapping?.vendor?.company,
              email: mapping?.vendor?.email,
              profilePhoto: mapping?.vendor?.profilePhoto,
            };
        });
        data.push(...result);
      }
      setVendors(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentVendors = async (pagination, sorting, rowCount, setRowCount, search = '') => {
    setLoading(true);
    try {
      // const params = { id: userId };
      const current = await apiV2.get(apiPath.fetchV2Vendors, { params: {page: pagination.pageIndex + 1, size: pagination.pageSize, search} });
      // const requests = await api.get(apiPath.fetchRequestedVendors, { params });
      const data = [];
      // if (requests?.data?.success) {
      //   const result = requests.data?.result?.rows
      //   .map((mapping) => {
      //     if (mapping.vendor1_id === userId) {
      //       return {
      //         id: mapping.id,
      //         isApproved: mapping.isApproved,
      //         isVendorProfileRequest: true,
      //         vendor2: mapping.vendor2,
      //         vendor_id: mapping.vendor2.id,
      //         firstName: mapping.vendor2.firstName,
      //         lastName: mapping.vendor2.lastName,
      //         status: "Requested",
      //         username: mapping.vendor2.username,
      //         sentByCurrentUser: true,
      //         companyName: mapping?.vendor2.company,
      //         email: mapping?.vendor2.email,
      //         profilePhoto: mapping?.vendor2.profilePhoto,
      //       };
      //     } else {
      //       return {
      //         id: mapping.id,
      //         isApproved: mapping.isApproved,
      //         isVendorProfileRequest: true,
      //         vendor1: mapping.vendor1,
      //         vendor_id: mapping.vendor1.id,
      //         firstName: mapping.vendor1.firstName,
      //         lastName: mapping.vendor1.lastName,
      //         status: "Requested",
      //         username: mapping.vendor1.username,
      //         sentByCurrentUser: false,
      //         companyName: mapping?.vendor1.company,
      //         email: mapping?.vendor1.email,
      //         profilePhoto: mapping?.vendor1.profilePhoto,
      //       };
      //     }
      //   });

      //   data.push(...result);
      // }

      if (current?.data?.status === "SUCCESS") {
        setRowCount(10 * current.data?.data?.meta?.pages)
        const result = (
          current.data?.data?.result?.length ? current.data?.data?.result : []
        ).map((mapping) => {
          if (mapping?.vendor1?.id === userId) {
            return {
              id: mapping.id,
              vendor_id: mapping.vendor2.id,
              firstName: mapping.vendor2.firstName,
              lastName: mapping.vendor2.lastName,
              isVendorProfileRequest: true,
              isShowApproveReject: mapping?.isShowApproveReject,
              status: mapping?.isApproved ? "Current" : "Requested",
              isApproved: mapping?.isApproved,
              username: mapping.vendor2.username,
              companyName: mapping?.vendor2.company,
              email: mapping?.vendor2.email,
              profilePhoto: mapping?.vendor2.profilePhoto,
              vendor2: {
                ...mapping?.vendor2
              }
            };
          } else {
            return {
              id: mapping.id,
              vendor_id: mapping.vendor1.id,
              firstName: mapping.vendor1.firstName,
              lastName: mapping.vendor1.lastName,
              isVendorProfileRequest: true,
              isShowApproveReject: mapping?.isShowApproveReject,
              status: mapping?.isApproved ? "Current" : "Requested",
              isApproved: mapping?.isApproved,
              username: mapping.vendor1.username,
              companyName: mapping?.vendor1.company,
              email: mapping?.vendor1.email,
              profilePhoto: mapping?.vendor1.profilePhoto,
              vendor1: {
                ...mapping?.vendor1
              }
            };
          }
        });

        data.push(...result);
      }
      setVendors(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchInvitedVendors = async (pagination, sorting, rowCount, setRowCount) => {

    const data = [];
    const invited = await api.get(apiPath.fetchInvitedUsers, {
      params: { userId, userType: roles.vendor, page: pagination.pageIndex + 1, size: pagination.pageSize },
    });

    
    if (invited?.data?.success) {
      setRowCount(10 * invited.data?.invite?.meta?.pages);
      const result = invited.data.invite?.result?.length ? invited.data?.invite?.result : [];
      data.push(
        ...result.map((item) => ({
          id: item.id,
          firstName: item.name,
          lastName: "",
          status: "Invited",
          companyName: item?.company,
          email: item?.email,
          profilePhoto: item?.profilePhoto,
        }))
      );
    }
    setInvitedVendors(data);
  }

  const sendRequestToVendor = async ({ username, email }) => {
    setLoading(true);
    const body = { id: userId, username, email };

    try {
      const res = await api.post(apiPath.requestNewVendor, body);
      if (res?.data?.success) {
        const { VVM, user } = res.data;
        setVendors([
          ...vendors,
          {
            id: VVM.id,
            firstName: user.firstName,
            lastName: user.lastName,
            status: "Requested",
            sentByCurrentUser: true,
          },
        ]);

        setLoading(false);
        Swal.fire({
          title: "Success",
          text: "Request sent successfully",
          icon: "success",
        });
        return true;
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      if (e?.response?.status >= 400 && e?.response?.status <= 500) {
        Swal.fire({
          title: "Error",
          text: e.response.data.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      }
      return false;
    }
  };

  const acceptRequestFromVendor = async (id) => {
    try {
      const res = await api.put(apiPath.acceptRequestFromVendor, { id });
      if (res?.data?.success) {
        const temp = [...vendors];
        const index = temp.findIndex((item) => item.id === id);
        temp[index].status = "Current";
        setVendors(temp);

        Swal.fire({
          title: "Success",
          text: "Request accepted successfully",
          icon: "success",
        });
      }
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  const declineRequestFromVendor = async (id) => {
    try {
      const res = await api.delete(apiPath.declineRequestFromVendor, {
        params: { id },
      });

      if (res?.data?.success) {
        const temp = [...vendors];
        const index = temp.findIndex((item) => item.id === id);
        temp.splice(index, 1);
        setVendors(temp);

        Swal.fire({
          title: "Success",
          text: "Request declined successfully",
          icon: "success",
        });
      }
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  const fetchCurrentConsultantsListforJob = async (search) => {
    setLoading(true);
    const isVendor = localStorage.getItem("role") === "VENDOR";
    const params = isVendor ? { type: "list", search } : {}
    try {
      const current = await apiV2.get(apiPath.fetchV2Resources, {
        params
      });

      if (current?.data?.status === "SUCCESS") {
        const result = current?.data?.data?.result?.length ? current?.data?.data?.result : [];
        if(isVendor) {
          setConsultants(result);
        } else {
          setConsultants(result?.map((item)=>({
            id: item?.consultant?.id,
            firstName: item?.consultant?.firstName,
            lastName: item?.consultant?.lastName
          })));
        }
      }

    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentConsultants = async () => {
    setLoading(true);
    const params = { id: userId, for: "VENDOR" };
    const data = [];
    try {
      const current = await api.get(apiPath.fetchCurrentConsultants, {
        params,
      });
      const invited = await api.get(apiPath.fetchInvitedUsers, {
        params: { userId, userType: roles.consultant },
      });
      if (current?.data?.success) {
        const result = (
          current.data?.result?.result?.length ? current.data.result.result : []
        ).map((item) => ({
          id: item.consultant_id,
          firstName: item?.consultant?.firstName,
          lastName: item?.consultant?.lastName,
          status: item.isApproved ? "Current" : "Requested",
          isApproved: item?.isApproved,
          requestFrom: item?.requestFrom,
          profilePhoto: item?.consultant?.profilePhoto,
          industryExperience: item.consultant?.industryExperience,
          skills: item.consultant?.skills
        }));
        data.push(...result);
      }
      if (invited?.data?.success) {
        const result = invited.data.invite?.length ? invited.data.invite : [];
        data.push(
          ...result.map((item) => ({
            id: item.id,
            firstName: item.name,
            lastName: "",
            status: "Invited",
            profilePhoto: item?.profilePhoto,
            email: item.email,
          }))
        );
      }
      setConsultants(unionBy(consultants, data, (item) => item.id));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const acceptRequestFromConsultant = async (id) => {
    const data = {
      vendor_id: userId,
      consultant_id: id,
    };
    api
      .put(`${apiPath.acceptRequestFromConsultant}?for=vendor`, data)
      .then((data) => {
        if (data.data.success) {
          Swal.fire({
            title: "Consultant Approved",
            icon: "success",
          });
          setConsultants((prev) =>
            prev.map((item) =>
              item.id === id ? { ...item, isApproved: true } : item
            )
          );
        } else {
          Swal.fire({
            title: "Some Error occurred",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",
          icon: "error",
        });
        console.error(err);
      });
  };
  const declineRequestFromConsultant = (id) => {
    const data = {
      vendor_id: userId,
      consultant_id: id,
    };
    api
      .delete(`${apiPath.declineRequestFromConsultant}?for=vendor`, { data })
      .then((data) => {
        if (data.data.success) {
          Swal.fire({
            title: "Consultant Rejected",
            icon: "success",
          });
          setConsultants((prev) =>
            prev.filter((item) => (item.id === id ? false : true))
          );
        } else {
          Swal.fire({
            title: "Some Error occurred",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",
          icon: "error",
        });
        console.error(err);
      });
  };
  const sendRequestToConsultant = async ({ username, email }) => {
    setLoading(true);
    const body = { id: userId, username, email, requestFrom: "VENDOR" };

    try {
      const res = await api.post(apiPath.requestNewConsultant, body);
      if (res?.data?.success) {
        const { VCM, user } = res.data;
        setConsultants([
          ...consultants,
          {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            status: "Requested",
            from: VCM.requestFrom,
            isApproved: VCM.isApproved,
          },
        ]);

        setLoading(false);
        Swal.fire({
          title: "Success",
          text: "Request sent successfully",
          icon: "success",
        });
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      if (e?.response?.status >= 400 && e?.response?.status <= 500) {
        Swal.fire({
          title: "Error",
          text: e.response.data.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
        });
      }
    }
  };

  const fetchClientsWithConsultantCount = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchClientsWithConsultantCount, {
        params: { userId },
      });

      if (res?.data?.success) {
        setClientsWithCount(Object.values(res.data.result));
      } else {
        throw new Error(
          "Error occurred while fetching clients with consultant count"
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchVendorsWithConsultantCount = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchVendorsWithConsultantCount, {
        params: { userId, for: "VENDOR" },
      });

      if (res?.data?.success) {
        setVendorsWithCount(Object.values(res.data.result));
      } else {
        throw new Error(
          "Error occurred while fetching vendors with consultant count"
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchConsultantsWithJob = async () => {
    setLoading(true);
    try {
      const res = await api.get(apiPath.fetchConsultantsWithJob, {
        params: { userId, for: "vendor" },
      });

      if (res?.data?.success) {
        const data = res.data.consultant;
        const temp = data.reduce((acc, curr) => {
          if (acc[curr.consultant_id]) {
            acc[curr.consultant_id].jobs.push(curr.job);
          } else {
            acc[curr.consultant_id] = {
              consultant: curr.consultant,
              jobs: [curr.job],
            };
          }

          return acc;
        }, {});

        setLoading(false);
        return Object.values(temp);
      } else {
        throw new Error("Error occurred while fetching consultants");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      return null;
    }
  };

  const inviteNewUser = async (user, showBanner = true) => {
    setLoading(true);
    try {
      const res = await api.post(apiPath.inviteNewUser, user);
      if (res?.data?.success) {
        if (showBanner) {
          Swal.fire({
            title: "Success",
            text: `Invitation successfully sent to ${user.emails?.length > 0 ? (user.emails).join(", ") : ""}`,
            icon: "success",
            showCloseButton: false,
            showConfirmButton: false,
            timer: 3000
          });
        }
        setLoading(false);
        return true;
      } else {
        Swal.fire({
          title: "Error",
          text: `Invitation already sent`,
          icon: "error",
          showCloseButton: false,
          showConfirmButton: false,
          timer: 3000
        });
        // throw new Error("Error occurred while inviting new user");
        setLoading(false);
        return false;
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      if (
        !e?.response?.data?.success
        // e?.response?.status >= 400 &&
        // e?.response?.status <= 500 &&
        // showBanner
      ) {
        Swal.fire({
          title: "Error",
          text: e.response?.data?.msg,
          icon: "error",
          timer: 3000,
          showCloseButton: false,
          showConfirmButton: false,
        });
      } else if (showBanner) {
        Swal.fire({
          title: "Error",
          text: "Something went wrong",
          timer: 3000,
          icon: "error",
          showCloseButton: false,
          showConfirmButton: false,
        });
      }
      setLoading(false);
      return false;
    }
  };

  async function importVendor (body, type) {
    try {
      setLoading(true);
      const res = await api.post(`${apiPath.vendorImport}?importType=${type}`, {
        csvFile: body
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      if(res.data?.success) {
        Swal.fire({
          icon : "success",
          title: "Info",
          text: `${res.data?.message} Check your notification`,
        })
        return res;
      } else {
        Swal.fire({
          icon : "error",
          title: "Info",
          text: `${res.data?.message} Check your notification`,
        })
        return res;
      } 
    } catch (error) {
      console.warn(error);
      return error;
    }
  }

  const value = {
    loading,
    clients,
    vendors,
    invitedVendors, 
    consultants,
    clientsWithCount,
    vendorsWithCount,
    fetchClients,
    fetchClientsList,
    fetchCurrentClients,
    sendRequestToClient,
    acceptRequestFromClient,
    declineRequestFromClient,
    fetchCurrentVendorsForClients,
    fetchCurrentVendors,
    fetchInvitedVendors,
    sendRequestToVendor,
    acceptRequestFromVendor,
    declineRequestFromVendor,
    fetchCurrentConsultants,
    fetchCurrentConsultantsListforJob,
    sendRequestToConsultant,
    fetchClientsWithConsultantCount,
    fetchVendorsWithConsultantCount,
    fetchConsultantsWithJob,
    inviteNewUser,
    acceptRequestFromConsultant,
    declineRequestFromConsultant,
    importVendor
  };

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionProvider;
