export const appliedJobsHeaders = [
  "COMPANY",
  "PROFILE",
  "APPLIED ON",
  "NUMBER OF APPLICANTS",
  "APPLICATION STATUS",
  "ACTIONS"
];

export const invitedJobsHeaders = [
  "JOB NAME",
  "INVITED BY",
  "ACTIONS"
]

export const sharedJobsHeaders = [
  "JOB NAME",
  "SHARED BY",
  "ACTIONS"
]