import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Box, Stack, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MuiModal from 'Components/Common/MuiModal';
import moment from "moment";
import ShareJobModal from 'Pages/Dashboard/components/Jobs/ShareJobModal';
import { ITEM_HEIGHT } from "Pages/MarketplaceNew/ApplicantsList";
import { getAllMarketplaceJobs, updateJobInMarketplace } from "Pages/MarketplaceNew/marketplace.api";
import { useAlertProvider } from "Providers/util/Alert";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { statuses } from "Utils/constants";
import { getUserIdFromLocalStorage } from "Utils/helper";

const options = [
  { label: 'Close Job', value: 'close' }
]

function JobListing() {
  const { success, error } = useAlertProvider()
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [shareJob, setShareJob] = useState(false)
  const [jobDetails, setJobDetails] = useState(null)
  const open = Boolean(anchorEl);

  const openHadler = (details) => {
    setShareJob(true)
    setJobDetails(details)
  }

  const handleMenuOpen = (event, jobId) => {
    setAnchorEl(event.currentTarget);
    setSelectedJobId(jobId);
  };

  const handleClick = async (id, value) => {
      setAnchorEl(null);
      const resp = await updateJobInMarketplace(id, { status: 'CLOSED' })
      if (resp?.success) {
        setSelectedJobId(null)
        success('Job Close Successfully')
        await jobHandler()
      } else error('Something went wrong')
  };

  const handleClose = () => {
      setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);

  const jobHandler = async () => {
    const userId = getUserIdFromLocalStorage();
    const resp = await getAllMarketplaceJobs(1, 100, '', '', '', 'DESC', `&createdBy=${userId}`);
    const job = resp?.data?.data || [];
    setJobs(job);
  };

  useEffect(() => {
    jobHandler();
  }, []);


  return (
    <Box>
      <Box sx={{ background: '#F1F2F4', padding: '10px 40px', marginBottom: '20px', borderRadius: '3.91px' }}>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography className="!text-sm w-[25%]">
            JOBS
          </Typography>
          <Typography className="!text-sm w-[25%]">
            STATUS
          </Typography>
          <Typography className="!text-sm w-[25%]">
            APPLICATIONS
          </Typography>
          <Typography className="!text-sm w-[25%]">
            ACTIONS
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ padding: '10px 40px', marginBottom: '20px', borderRadius: '3.91px' }}>
        {jobs &&
          jobs.map((job) => {
            const isMenuOpen = selectedJobId === job.id;
            return (<Box
              sx={{
                borderBottom: "1px solid #E5E7EB",
                paddingBottom: '10px',
                width: '100%',
                marginBottom: "10px",
              }}
            >
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography className="!text-xs w-[25%]">
                  <strong>{job?.name}</strong><br />
                  <span className="!text-normal !mt-2">
                    Posted on{" "} {moment(job?.created_at).format("DD MMM, YYYY")}
                  </span>
                </Typography>
                <Typography className='w-[25%]'>
                  {[statuses.job.open].includes(job?.status)
                    ? <Box className='!font-bold !text-xs' sx={{ color: '#0BA02C', display: 'flex', alignItems: 'center', gap: '4px' }}><CheckCircleIcon /> Active</Box>
                    :  job?.status === 'HIRED'
                    ? <Box className='!font-bold !text-xs' sx={{ color: '#800080', display: 'flex', alignItems: 'center', gap: '4px' }}><CheckCircleIcon /> HIRED</Box>
                    : <Box className='!font-bold !text-xs' sx={{ color: '#E05151', display: 'flex', alignItems: 'center', gap: '4px' }}><CancelIcon />CLOSED</Box>
                  }
                </Typography>
                <Typography className="!text-xs w-[25%]">
                  <PeopleOutlineIcon /> {job?.applicant_count > 0 ? `${job?.applicant_count} Applications` : "0 Applications"}
                </Typography>
                <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} className='w-[25%]'>
                  <Typography
                    className='text-[#0B85BA] cursor-pointer'
                    onClick={() =>
                      navigate(`/dashboard/marketplace/applicants/${job.id}`)
                    }
                  >
                    View Applications
                  </Typography>
                  {!(job.status === 'HIRED' || job.status === 'CLOSED') && (
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={(event) => handleMenuOpen(event, job.id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}
                  <Menu
                    id={selectedJobId}
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                        boxShadow: 'none',
                        border: '1px solid #E9EAED',
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={(e) => (e.stopPropagation(),handleClick(selectedJobId, option.value))}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem key={'share-job'} onClick={(e) => (e.stopPropagation(),openHadler(job),setSelectedJobId(null))}>
                      Share Job
                    </MenuItem>
                  </Menu>
                </Stack>
              </Stack>
            </Box>
          )})}
      </Box>
      {shareJob &&
        <MuiModal open={shareJob} onCancel={() => setShareJob(false)} className="max-w-5xl">
          <ShareJobModal details={jobDetails} closeModal={() => setShareJob(false)} />
        </MuiModal>}
    </Box>
  );
}

export default JobListing;
