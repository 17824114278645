import api, { marketPlaceUrl } from "Utils/api"

export const marketPlaceEndpoints = {
    addNewJob: 'api/job-application',
    jobInvite: 'api/job-invite',
    shareJob: 'api/job-invite/share_job_vendor',
    childJob: 'api/market-place-jobs/createChildJob',
}

export const addNewJobInMarketplace = async (data) => {
    try {
        const resp = await api.post(`${marketPlaceUrl}${marketPlaceEndpoints.addNewJob}`, data)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data?.result || [] };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const shareJob = async (data) => {
    try {
        const resp = await api.post(`${marketPlaceUrl}${marketPlaceEndpoints.shareJob}`, data)
        if (resp && resp.data && resp.data.success) {
            return { success: true, data: resp.data?.result || [] };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const fetchJobDetails = async (id) => {
    try {
        const resp = await api.get(`${marketPlaceUrl}${marketPlaceEndpoints.addNewJob}?applicant_id=${id}`)
        if (resp && resp.data) {
            return { success: true, data: resp.data?.data || [] };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

export const sendJobInvite = async (payload)=> {
    try {
      const res = await api.post(`${marketPlaceUrl}${marketPlaceEndpoints.jobInvite}`, payload)
      return res;
    } catch (error) {
      console.warn(error);
      return error;
    }
  }

export const fetchInvitedJobs = async(id, query) => {
  try {
    const res = await api.get(`${marketPlaceUrl}${marketPlaceEndpoints.jobInvite}?user_id=${id}${query}`);
    return res;
  } catch (error) {
    console.warn(error);
    return error;
  }
}

export const postJobInMarketplaces = async(data) => {
  try {
    const res = await api.post(`${marketPlaceUrl}${marketPlaceEndpoints.childJob}`, data);
    if (res && res.data && res.data) {
        return { success: true, message: 'success' };
    };
    return { success: false, message: 'Something went wrong' };
  } catch (error) {
    return { success: false, message: error?.response?.data?.message || 'Something went wrong' };
  }
}

export const updateJobApplicationInMarketplace = async (id, data) => {
    try {
        const resp = await api.put(`${marketPlaceUrl}${marketPlaceEndpoints.addNewJob}/${id}`, data)
        if (resp && resp.data && resp.data.success) {
            return { success: true, message: 'success' };
        };
        return { success: false, message: 'No task found' };
    } catch (e) {
        return { success: false, message: 'No task found' };
    }
}

//{"applicant_id": "applicant_123","job_owner_id": "owner_456","job_id": "job_789","vendor_id": "vendor_101","status": "PENDING","type": "full-time","feedback": "Looking forward to this opportunity."}





