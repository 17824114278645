import Typography from "Components/Common/semantic_tags/Typography";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MuiModal from "Components/Common/MuiModal";
import CustomInput from "Components/Common/semantic_tags/Input";
import { Controller, useForm } from "react-hook-form";
import CustomDateInput from "Components/Common/semantic_tags/CustomDateInput";
import Button from "Components/Common/semantic_tags/Button";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { addEducation, deleteEducation, updateEducation } from "./api";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { selectProfileDetails, selectResourceDetails } from "Redux/Feature/profile/profile.selector";
import { useDispatch } from "react-redux";
import { addEducationData, deleteEducationData, updateEducationData } from "Redux/Feature/profile/profile.slice";
import dayjs from "dayjs";
import { useAlertProvider } from "Providers/util/Alert";
import SchoolIcon from "@mui/icons-material/School";
import DeleteIcon from "@mui/icons-material/Delete";
import { EducationSchema } from "./EducationSchema";
import moment from "moment";
import { EducationIllustration } from "Assets/SvgIcons";

const Education = () => {
  const defaultVal = {
    institution: "",
    degree: "",
    start_year: "",
    end_year: "",
  };
  const { error, success } = useAlertProvider();
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const isResourceEditing = useSelector((state) => state.profile.isResourceEditing)
  const profile = useSelector(isResourceEditing ? selectResourceDetails : selectProfileDetails);
  const [isEdit, setIsEdit] = useState(false);
  const [editDetails, setEditDetails] = useState(defaultVal);

  const editHandler = (details) => {    
    setIsEdit(true);
    setEditDetails({
      ...details,
      start_year: dayjs(`${details?.start_year}-01-01`),
      end_year: dayjs(`${details?.end_year}-01-01`),
    });
    setAddModal(true);
  };

  const deleteHandler = (item) => {
    setSelectedItem(item);
    setDeleteModal(true);
  };

  const onDelete = async () => {
    if (selectedItem) {
      const resp = await deleteEducation(selectedItem.id, isResourceEditing, profile?.user_id);
      if (resp.success) {
        dispatch(deleteEducationData({ id: selectedItem.id }));
        success("Deleted Successfully");
        setDeleteModal(false);
      } else error(resp?.message || "Something went wrong");
    } else {
      error("No Education found");
    }
  };

  return (
    <div>
      <>
        {profile?.education?.length === 0 ? (
          <div className="flex min-[300px]:flex-col md:flex-row justify-center items-center min-h-[50vh]">
            <div className="flex flex-col relative min-[300px]:w-full:w-[35%]">
              <EducationIllustration />
            </div>
            <div className="flex flex-col min-[300px]:w-full md:w-[40%] min-[300px]:pt-4 md:pl-4">
              <Typography className='font-bold'>Education</Typography>
              <Typography className='text-xs'>Boost your professional profile by adding your educational qualifications to ProLegion. Whether you’ve earned a degree, completed a certification, or attended specialized training, documenting your education showcases your commitment to growth and expertise in your field. This not only highlights your achievements but also enhances your credibility within the ProLegion community. It makes it easier for potential employers, collaborators, and peers to recognize your qualifications and opens doors to new opportunities. By keeping your educational records up-to-date, you position yourself as a knowledgeable and proactive professional ready to seize the next big opportunity. Don't miss out add your education details to your ProLegion profile today and let your achievements speak for themselves.</Typography>
              <Typography onClick={() => setAddModal(true)} className='text-xs text-[#3985B6] font-bold cursor-pointer mt-8'><AddCircleOutlineIcon /> Add Education</Typography>
            </div>
          </div>
        ) : (
          <>
            <div className="flex w-full justify-between items-center">
              <Typography className="font-bold text-xl">Education</Typography>
              <Typography onClick={() => setAddModal(true)} className="text-xs text-[#3985B6] font-bold cursor-pointer"> <AddCircleOutlineIcon /> Add New </Typography>
            </div>
            {profile?.education?.length > 0 && (
              <div className="pl-6 pt-4">
                {(profile?.education || []).map((item, index) => (
                  <EducationCard key={index} deleteHandler={deleteHandler} isLast={index === profile?.education?.length -1} item={item} setEditDetails={editHandler} edit={true} />
                ))}
              </div>
            )}
          </>
        )}
        {addModal && (
          <AddEducationModal isResourceEditing={isResourceEditing} defaultValues={editDetails} isEdit={!!isEdit} profile={profile} open={addModal} 
            isClose={() => (
              setAddModal(false), setIsEdit(false), setEditDetails(defaultVal)
            )}
          />
        )}
        {deleteModal && (
          <DeleteModal onDelete={onDelete} open={deleteModal} isClose={() => (setDeleteModal(false), setSelectedItem(null))} />
        )}
      </>
    </div>
  );
};

export const EducationCard = ({ item, edit, isLast, setEditDetails, deleteHandler = (data) => {}, isPublic = false }) => {
  return (
    <div className={`flex items-start pb-4 ${!isLast && "border-l-2"}`}>
      <div className={`h-[50px] w-[50px] rounded-full bg-[#3985B6] flex justify-center items-center ${ isPublic ? "" : "ml-[-25px]"}`}>
        <SchoolIcon className="" sx={{ fontSize: "30px", color: "white" }} />
      </div>
      <div className="bg-white p-4 pt-0 rounded-lg mb-4 border-t-1 w-full">
        <div className="flex items-center justify-between mb-2 w-full">
          <div className="">
            <h2 className="font-semibold flex items-center text-lg text-[#6B7280]">
              {item.institution}
              {edit && (
                <ModeEditOutlineIcon onClick={() => setEditDetails(item)} className="text-[#3985B6] cursor-pointer ml-2" sx={{ fontSize: "16px" }} />
              )}
              {edit && (
                <DeleteIcon onClick={() => deleteHandler(item)} className="text-red-500 cursor-pointer ml-2" sx={{ fontSize: "16px" }} />
              )}
            </h2>
            <div className="text-[#6B7280] text-sm">{item.degree}</div>
            {item.start_year && <div className="flex items-center text-sm text-[#6B7280]">
              {item.start_year ? item.start_year : ""} {item.start_year ? " - " :""}{" "} {item.end_year ? item.end_year : "Ongoing"}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DeleteModal = ({ open, isClose, onDelete, text = 'Education' }) => {
  return (
    <MuiModal open={open} onCancel={isClose}>
      <div className="flex flex-col">
        <Typography className="text-xl font-bold"> Are you sure you want to delete the {text} ?</Typography>
        <div className="flex justify-end mt-8">
          <Button title="Cancel" onClick={isClose} />
          <Button title="Delete" className="!border-[unset] hover:!bg-red-500 !bg-red-500 ml-4" onClick={onDelete} />
        </div>
      </div>
    </MuiModal>
  );
};

const AddEducationModal = ({ open, isClose, defaultValues, isEdit, profile, isResourceEditing }) => {
  const dispatch = useDispatch();
  const { success, error } = useAlertProvider();
  const { control, handleSubmit, watch, reset, formState: { errors }, } = useForm({ defaultValues, resolver: yupResolver(EducationSchema) });

  const start_year = watch("start_year");

  const onSubmit = async (data) => {    
    let resp = { success: false };
    const payload = { institution: data?.institution, degree: data?.degree, start_year: moment(data?.start_year).year(), end_year: moment(data?.end_year).year() }
    if (isEdit) {
      resp = await updateEducation(data.id, payload, isResourceEditing, profile?.user_id);
    } else {
      resp = await addEducation(payload, isResourceEditing, profile?.user_id, profile?.role);
    }

    if (resp.success) {
      success(isEdit ? "Education updated successfully" : "Education added successfully");
      isClose();
      isEdit ? dispatch(updateEducationData(resp.data)) : dispatch(addEducationData(resp.data));
    } else {
      error(resp?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <MuiModal open={open} onCancel={isClose} title={isEdit ? "Edit Education" : "Add Education"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Controller name="institution" control={control}
            render={({ field }) => (
              <CustomInput {...field} label="Institution*" error={errors.institution?.message} />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller name="degree" control={control}
            render={({ field }) => (
              <CustomInput {...field} label="Degree*" error={errors.degree?.message} />
            )}
          />
        </div>
        <div className="flex min-[300px]:flex-col md:flex-row md:space-x-4 mb-4">
          <div className="flex-1">
            <Controller name="start_year" control={control}
              render={({ field }) => (
                <CustomDateInput {...field} mode="year" label="Start Year*" error={errors.start_year?.message} />
              )}
            />
          </div>
          <div className="flex-1 min-[300px]:pt-4 md:pt-0">
            <Controller name="end_year" control={control}
              render={({ field }) => (
                <CustomDateInput {...field} mode="year" minDate={dayjs(start_year)} maxDate={dayjs(`${new Date().getFullYear() + 50}-01-01`)} label="End Year (or Expected)" error={errors.end_year?.message} />
              )}
            />
          </div>
        </div>
        <Button type="submit" parentClassName="w-full mt-12" className="w-full" title={isEdit ? "Update" : "Save"} />
      </form>
    </MuiModal>
  );
};

export default Education;
