import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useVendorProvider } from '../../../../Providers/Client/Vendor';
import { CustomTable } from 'Components';
import { roles, userPermissions } from '../../../../Utils/constants';
import { getPermissions } from 'Utils/helper';
import { useConnectionProvider } from 'Providers/Vendor/Connections';
import { selectUserDetails, selectUserRole } from 'Redux/Feature/user';
import { Avatar, Box, Tab, Tabs, Typography } from '@mui/material';
import { stringAvatar } from '../../../../Utils/avatar';
import { Link } from 'react-router-dom';

export default function CurrentVendors({ name }) {
  const { isClient } = useSelector(selectUserRole)
  const user = useSelector(selectUserDetails)
  const { acceptRequestFromClient, declineRequestFromClient ,fetchCurrentVendors, fetchCurrentVendorsForClients, fetchInvitedVendors, vendors, invitedVendors, acceptRequestFromVendor, declineRequestFromVendor, loading } = useConnectionProvider();
  const userRole = useSelector(selectUserRole);
  const permissions = getPermissions();
  const [tabsIndex, setTabsIndex] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    pageIndex: 0,
  });
  
  const [sorting, setSorting] = React.useState([
    { id: "createdAt", desc: true },
  ]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleToggleTabs = (event, newIndex) => {
    setTabsIndex(newIndex)
  }

  useMemo(()=> {
    setPagination({
      pageSize: 10,
      pageIndex: 0,
    })
  },[tabsIndex]);

  useEffect(() => {
    if (user && user?.user_id) {
      if (
        permissions?.includes('admin-client')
        || permissions?.includes(userPermissions.vendorConnection.view)
      ) {
        if(userRole.isVendor) {
          tabsIndex === 0 ? fetchCurrentVendors(pagination, sorting, rowCount, setRowCount) 
          : fetchInvitedVendors(pagination, sorting, rowCount, setRowCount)
        }
      } if(userRole.isClient) {
        tabsIndex === 0 ? fetchCurrentVendorsForClients(pagination, sorting, rowCount, setRowCount) :
        fetchInvitedVendors(pagination, sorting, rowCount, setRowCount)
      }
    }
  }, [pagination.pageIndex, tabsIndex, user]);  

  const vendorColumnDefs = {
    name: {
      accessorKey: "companyName",
      header: `${name}s`,
      Cell: ({ row, cell }) => {
        return (
          <Link target="_blank" to={`${row?.original?.status === "Invited" ? "" : `/${row?.original?.username}`}`}>
            <div className="flex items-center">
              <Avatar
                {...stringAvatar(
                  row?.original?.companyName
                    ? row?.original?.companyName
                    : row?.original?.firstName
                      ? `${row?.original?.firstName} ${row?.original?.lastName}`
                      : row?.original?.email,
                )}
                className="mr-2.5"
                size="large"
                src={row?.original?.profilePhoto}
              />
              <span>
                {tabsIndex === 0
                  ? row?.original?.companyName
                  : row?.original?.email}
              </span>
            </div>
          </Link>
        )
      },
    },
    status: {
      accessorKey: "status",
      header: "Status",
      Cell: ({row, cell}) => {
        return <>
        {
        (row?.original?.isShowApproveReject) || (row.original?.requestFrom === roles.vendor && !row.original?.isApproved) ? (
          (permissions?.includes(userPermissions.vendorConnection.add) || isClient) ? (
            <div className="flex gap-x-4">
              <button
                className="bg-green-400 text-white px-2 py-1 rounded-md"
                onClick={() => row?.original?.isVendorProfileRequest ? acceptRequestFromVendor(row?.original?.id) : acceptRequestFromClient(row?.original?.id, true)}
              >
                Accept
              </button>
              <button
                className="bg-red-400 text-white px-2 py-1 rounded-md"
                onClick={() =>row?.original?.isVendorProfileRequest ? declineRequestFromVendor(row?.original?.id) : declineRequestFromClient(row?.original?.id, true)}
              >
                Reject
              </button>
            </div>
          ) : null
        ) : (
          row?.original?.status
        )}
        </>
      }
    }
  }

  const vendorColumns = {
    vendors:[
      vendorColumnDefs.name,
      vendorColumnDefs.status
    ]
  }
  return (
    <>
    <Tabs value={tabsIndex} onChange={handleToggleTabs} sx={{borderTopLeftRadius: "8px", borderTopRightRadius: "8px", boxShadow: "0 0 4px #e5e7eb"}}>
      <Tab label={"Current"} {...a11yProps(0)} />
      <Tab label={"Invites"} {...a11yProps(1)} />
    </Tabs>
    <CustomTable
      state={{
        isLoading: loading,
        sorting,
        pagination,
      }}
      data={tabsIndex === 0 ? vendors : invitedVendors}
      columns={vendorColumns.vendors}
      manualPagination
      paginationDisplayMode="pages"
      rowCount={rowCount}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
    />
    </>
  );
}
