import Typography from "Components/Common/semantic_tags/Typography";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from "moment";

const { Avatar, Box, Button, Chip, Tooltip } = require("@mui/material");
const { stringAvatar } = require("Utils/avatar");
const { truncateText } = require("Utils/helper");

const JobCard = ({ jobTitle, experience, availability, company, location, updated_at, isGrid, description, profilePhoto, skills, alreadyApplied, setSelectedDetail, isDrawer = false, type }) => (
    <div className={`w-full bg-white ${isGrid ? 'min-h-[240px]' : 'min-h-[100px]'} p-4 rounded-lg mb-2 ${isDrawer ? 'border-[1px] border-solid border-[#E5E7EB] pb-6' : 'border-[1px] border-solid border-[#E5E7EB]'} overflow-hidden flex justify-between flex-col cursor-pointer pb-6`} onClick={setSelectedDetail}>
        <div className="">
            <div className="flex justify-between items-start">
                <div className='flex gap-4'>
                    <div>
                        {profilePhoto ? (
                            <Avatar
                                variant="square"
                                alt="Profile"
                                className="!rounded-lg !text-xs"
                                src={profilePhoto}
                                sx={{ width: 40, height: 40 }}
                            />
                        ) : (
                            <Avatar
                                variant="square"
                                className="!rounded-lg !text-xs"
                                {...stringAvatar(company ?? "ProLegion User", {
                                    width: 40,
                                    height: 40,
                                })}
                            />
                        )}
                    </div>
                    <div className={`flex items-start justify-between w-full ${type === 'similar' ? 'flex-col' : ''}`}>
                        <div>
                            <h3 className={`text-lg font-bold line-clamp-1 text-[#3985B6]`}>{jobTitle}</h3>
                            <p className="text-gray-500 text-sm">
                                {company || 'Prolegion'} •
                                {location}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {type !== 'similar' && description && (
                <Box
                    className="text-sm mt-4 uniform-font max-h-[50px] overflow-hidden text-ellipsis"
                    sx={{
                        color: '#6B7280',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        display: '-webkit-box',
                        WebkitLineClamp: isDrawer ? 2 : 3,
                        WebkitBoxOrient: 'vertical',
                    }}
                    dangerouslySetInnerHTML={{ __html: truncateText(description, isGrid ? 20 : 60) }}
                ></Box>
            )}
            <div className="flex gap-2 items-center line-clamp-1 text-sm mt-4">
                {
                    skills?.length > 0 && skills.map((skill, index) => {
                        return <Chip
                            key={index}
                            sx={{ borderRadius: '6px', fontWeight: 600, background: '#F9FAFB', border: "1px solid #F9FAFB", fontSize: '12px', padding: '1px' }}
                            label={skill}
                            variant="outlined"
                            clickable
                        />
                    })
                }
                {/* <strong>Required Skills:-</strong> {skills?.length > 10 ? skills.splice(10).join(', ') + '...' : skills.join(', ')} */}
            </div>
            <div className="flex items-center justify-between mt-2">
                <div className="w-full flex flex-col justify-end items-end">
                    <div className={`flex items-center gap-4`}>
                        {experience > 0 && <Typography className={`${isGrid ? 'text-xs' : 'text-sm'}`}><strong>Preferred experience:</strong> {experience} years</Typography>}
                        {availability && <Typography className={`${isGrid ? 'text-xs' : 'text-sm'}`}><strong>Availability:</strong> {availability === 'Immediate' ? availability : `${availability} days`}</Typography>}
                    </div>
                    {!isGrid && <Typography className="text-xs">Posted {moment(updated_at).fromNow()}</Typography>}
                </div>
            </div>
        </div>
        {/* {!isDrawer && <div className='flex justify-between mt-4 items-center'>
            <div className='flex items-center'>
                {applicant_count >= 1 && <h4 className='text-xs font-normal'>{applicant_count} Resources</h4>}
                &nbsp;{" • "}&nbsp;
                <h4 className='text-xs font-normal'>{moment(new Date(createdAt)).fromNow()}</h4>
            </div>
            <Button variant='contained' sx={{ padding: '6px 10px', fontSize: '10px' }}>Apply Now</Button>
        </div>} */}
    </div>
);

export default JobCard