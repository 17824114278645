import { Autocomplete, Checkbox, Chip, TextField, FormControlLabel } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import AddressSection from "Pages/editProfile/common/AddressSection";
import { useJobProvider } from "Providers/Client/Jobs";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { jobsDataSelector } from "Redux/Feature/jobs";
import { availabilityOptions, currencyTypeOptions, locationOptions, rateTypeOptions } from "./jobs.constant";
import CustomInput from "Components/Common/semantic_tags/Input";
import Button from "Components/Common/semantic_tags/Button";

const JobDetails = ({ jobDetails, updateHandler, isValid, isError, setJobPage }) => {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;
  const muiListfilter = createFilterOptions();
  const { getAllSkills, skills } = useJobProvider();
  const { country, state, city } = jobDetails

  const { jobCategories } = useSelector(jobsDataSelector);

  useEffect(() => {
    getAllSkills();
  }, []);

  const sortedSkills = useMemo(() => (skills.sort((a, b) => a.name.localeCompare(b.name))), [skills]);
  const jobCategoryData = useMemo(() => (jobCategories.map((item) => ({ label: item.category, value: item.id }))), [jobDetails?.jobCategoryList])

  const handleFilterCreateOption = (options, params) => {
    const filtered = muiListfilter(options, params);
    const { inputValue } = params;
    const isExistingValue = options.some((option) => inputValue === option.name);
    if (inputValue !== '' && !isExistingValue) {
      filtered.push({
        inputValue,
        name: `${inputValue}`
      })
    }
    return filtered;
  }

  const handleForwardPage = () => {
    if (!jobDetails?.name || !jobDetails?.category_id || jobDetails?.main_skill.length === 0 || !jobDetails?.currency || !jobDetails?.rate_type || !jobDetails?.location) {
      updateHandler(true, "isError")
    } else {
      setJobPage((prev) => prev + 1);
      updateHandler(false, "isError")
    }
  }

  return (
    <div className="flex flex-col pb-5">
      <div className="flex mb-5">
        <h6 className="text-lg font-bold">Job details</h6>
      </div>
      <div className="flex flex-col gap-6 w-full h-full">
        <CustomInput
          className="w-full"
          value={jobDetails.name}
          onChange={(e) => updateHandler(e.target.value, "name")}
          label="Job title"
          required
          placeholder="Enter job title"
        />
        {isValid?.isNotValidname && isError && <div className="pt-0"><p className="text-red-500 text-[10px]">Job Title Required</p></div>}

        <Autocomplete
          className="w-full"
          clearIcon={false}
          onChange={(_, value) => updateHandler(value?.label, "category_id")}
          options={jobCategoryData}
          getOptionLabel={(option) => (option?.label ? option.label : option)}
          value={jobDetails?.id && jobDetails?.jobCategory ? jobDetails?.jobCategory?.category : jobDetails.category_id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Job Category"
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
        {isValid?.isNotValidJobCategory && isError && <div className="pt-0"><p className="text-red-500 text-[10px]">Job Category Required</p></div>}

        <Autocomplete
          multiple
          clearIcon={false}
          className="w-full"
          value={jobDetails?.main_skill ? jobDetails.main_skill : []}
          defaultValue={
            jobDetails?.main_skill
              ? jobDetails.main_skill.map((i) => {
                return { name: i };
              })
              : []
          }
          getOptionLabel={(option) => (option.name ? option.name : option)}
          filterOptions={handleFilterCreateOption}
          onChange={(_, v) => updateHandler(v, "main_skill")}
          options={sortedSkills}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.name ? option.name : option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Skills"
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
        {isValid?.isSkillEmpty && isError && <div className="pt-0"><p className="text-red-500 text-[10px]">Skills Required</p></div>}

        <div className="grid lg:grid-cols-3 gap-8">
          <Autocomplete
            clearIcon={false}
            value={jobDetails?.currency}
            getOptionLabel={(option) => (option?.label ? option.label : option)}
            onChange={(_, v) => updateHandler(v?.value, "currency")}
            options={currencyTypeOptions}
            // className="w-[33%]"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Currency"
                required
                className="w-[33%]"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
          {isValid?.isNotCurrency && isError && <div className="pt-0"><p className="text-red-500 text-[10px]">Currency Required</p></div>}

          <CustomInput
            type="number"
            className="w-full"
            label="Rate"
            placeholder="eg. 30"
            value={jobDetails.rate}
            onChange={(e) => updateHandler(e.target.value, "rate")}
          />
          {isValid?.isNotRate && isError && <div className="pt-0"><p className="text-red-500 text-[10px]">Rate Cannot be less than 0</p></div>}

          <Autocomplete
            clearIcon={false}
            value={jobDetails?.rate_type}
            getOptionLabel={(option) => (option?.label ? option.label : option)}
            onChange={(_, v) => updateHandler(v?.value, "rate_type")}
            options={rateTypeOptions}
            // className="w-[33%]"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Rate Type"
                className="w-[33%]"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
          {isValid?.isNotRateType && isError && <div className="pt-0"><p className="text-red-500 text-[10px]">Rate Type Required</p></div>}
        </div>

        <div className="grid grid-cols-3 gap-8">
          <Autocomplete
            clearIcon={false}
            value={jobDetails?.location}
            getOptionLabel={(option) =>
              option?.label ? option?.label : option
            }
            onChange={(_, v) => updateHandler(v?.value, "location")}
            options={locationOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Location"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
          <div>
            <CustomInput type="number" label="Experience (in years)" placeholder='eg. 2.5' value={jobDetails?.experience} onChange={(e) => updateHandler(e.target.value, "experience")} />
            {isValid?.experience && isError && <div className="pt-0"><p className="text-red-500 text-[10px]">Invalid Experience</p></div>}
          </div>
          <div>
            <Autocomplete
              clearIcon={false}
              value={jobDetails?.availability}
              getOptionLabel={(option) =>
                option?.label ? option?.label : option
              }
              onChange={(_, v) => updateHandler(v?.value, "availability")}
              options={availabilityOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Availability"
                  required
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </div>
        </div>
        {isValid?.isNotLocation && isError && <div className="pt-0"><p className="text-red-500 text-[10px]">Job Location Required</p></div>}
        {jobDetails.location !== "REMOTE" && (
          <AddressSection
            isStreetRequired={false}
            address={{ country, city, state }}
            setAddress={updateHandler}
            isValid={isValid}
            isError={isError}
          />
        )}

        <div className="w-full">
          {/* <p className="text-sm font-semibold">Job Start Date</p> */}
          {/* <DesktopDatePicker
              className="w-full mt-2"
              format="MM-DD-YYYY"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                },
                '& .MuiInputBase-root': {
                  backgroundColor: 'white',
                  marginTop: '5px'
                },
                '& .MuiInputAdornment-root': {
                  backgroundColor: 'white'
                },
              }}
              renderInput={(params) => <TextField {...params} />}
              value={jobDetails.start_date ? dayjs(jobDetails.start_date) : null}
              onChange={(e) => updateHandler(e, "start_date")}
              minDate={dayjs()}
            /> */}
        </div>

        <div className="flex justify-end mt-2">
          <Button onClick={handleForwardPage} title={"Next"} className="border-[#3985B6] w-52" />
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
