import axios from "axios";
import api, { baseURL, documentUploadURL } from "../../Utils/api";
import Swal from "sweetalert2";
import { removeKeysFromObject } from "Utils/helper";

export const uploadFile = async ({ cur, user, photo_type = 'profile_photo', content = 'image' }) => {
    if (!user?.id || !cur) return { success: false, error: 'invalid parameter' };

    if (cur.type.indexOf(content) === -1 || cur.size > 10000000) {
        const sizeIssue = cur.size > 10000000;
        const text = sizeIssue
            ? 'File size should be less than 10 MB'
            : 'Please upload an image file'
        return { success: false, error: text };
    }
    const formData = new FormData()
    formData.append('file', cur)
    try {
        const upload = await api.post(`${documentUploadURL}document/upload/${user.id}/${photo_type}/${user.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (upload?.data?.data && upload?.data?.data.length > 0) {
            return { success: true, url: upload?.data?.data[0].s3_bucket_path, id: upload?.data?.data[0].id }
        }
    } catch (err) {
        return { success: false, error: 'Something went wrong' }
    }
};

export const uploadVideo = async ({ cur, user, photo_type = 'profile_video', content = 'video' }, setProgress) => {
  if (!user?.id || !cur) return { success: false, error: 'invalid parameter' };

  if (cur.type.indexOf(content) === -1 || cur.size > 20000000) {
    const sizeIssue = cur.size > 20000000;
    const text = sizeIssue
      ? 'File size should be less than 20 MB'
      : 'Please upload an image file';
    return { success: false, error: text };
  }

  const formData = new FormData();
  formData.append('file', cur, 'profile_video');

  try {
    const isHybridConsultantCheck = localStorage.getItem('isHybridConsultant');
    const role = localStorage.getItem('role');
    const upload = await axios.post(`${documentUploadURL}document/upload/${user.user_id}/${photo_type}/${user.user_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('at')}`,
        ...(isHybridConsultantCheck && role === 'VENDOR' && { isHybridConsultant: true }),
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percent)
        return percent;
      }
    });

    if (upload?.data?.data && upload?.data?.data.length > 0) {
      return { success: true, url: upload?.data?.data[0].s3_bucket_path, id: upload?.data?.data[0].id }
    }
  } catch (err) {
    return { success: false, error: 'Something went wrong' }
  }
};

export const updateAvatar = async (url, user, isEdit, id) => {
    const updatedUser = await UpdateUser({
        ...(user.keycloakId && { keycloakId: user.keycloakId }),
        profile_image: url,
    },'', isEdit, id);
    if (updatedUser) {
        return { success: true, message: 'Your profile picture has been updated', updatedUser }
    } else {
        throw new Error("Something went wrong");
    }
};

export const UserInfo = async () => {
  const isHybridConsultant = localStorage.getItem('isHybridConsultant');
  const role = localStorage.getItem('role');
  const hybridConsultantParam = role === "VENDOR" ? "?isHybridConsultant=true" : "?isHybridConsultant=false"
  
  return api
    .get(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles${isHybridConsultant ? hybridConsultantParam : ""}`)
    .then((data) => {
      return {
        data: {
          success: true,
          result: data.data,
        },
      };
    })
    .catch((err) => {
      return err;
    });
};

export const publicProfileDetails = async (id) => {
  const vendor_id = localStorage.getItem('userId')
  return api
    .get(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles/${id}${vendor_id ? `?vendor_id=${vendor_id}` : ''}`)
    .then((data) => {
      return {
        data: {
          success: true,
          result: data.data,
        },
      };
    })
    .catch((err) => {
      return err;
    });
};

export const OrgInfo = async (id) => {
    return api.get(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/organisation/${id}`)
    .then((data) => {
        return {
          data: {
            success: true,
            result: data.data,
          },
        };
      })
      .catch((err) => {
        return err;
      });
}


export const UpdateUser = async (body, role, isEdit, id) => {
  const isHybridConsultantUser = localStorage.getItem('isHybridConsultant');
  const params = isHybridConsultantUser ? {
    isHybridConsultant : role === 'VENDOR' ? true : false 
  } : {}
    return api
        .patch(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles${isEdit ? `?user_id=${id}` : ''}`, body, { params })
        .then((res) => res.data)
        .catch((err) => {
            return { success: false, message: err?.response?.data?.error };
        });
}
;
export const UpdateReference = async (body, referenceId) => {
  return api
    .patch(
      `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles/references/${referenceId}`,
      body
    )
    .then((res) => res.data)
    .then((res) => { return { success: true, data: res }})
    .catch((err) => {
      return { success: false, message: err?.response?.data?.error };
    });
};

export const UpdateOrg = async (body) => {
    const id = body.id
    delete body.id
    return api
        .patch(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/organisation/${id}`, body)
        .then((res) => res.data)
        .catch((err) => {
            return { success: false, message: err?.data?.error };
        });
};

export const getAllSkills = async () => {
    const res = await api.get('/jobnproject/getallskills');
    if (res.data?.success) {
        return res.data.skill.rows;
    } else {
        return []
    }
};

export const addNewCertificate = async (data, isResourceEditing, id, role) => {
  return api
    .post(
      `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/certificates${isResourceEditing ? `?user_id=${id}` : ""}${role ? `${isResourceEditing ? '&' : '?'}role=${role}` : ''}`,
      data
    )
};

export const deleteCertificateById = async (id, isResourceEditing, userId) => {
  return api
    .delete(
      `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/certificates/${id}${isResourceEditing ? `?user_id=${userId}` : ""}`)
};

export const addEmploymentDetails = async (id, data) => {
  return api
    .post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/user/becomehybridconsultant`,
      data
    )
};

export const sendInvite = async (user, showBanner = true) => {
  try {
    const res = await api.post('/invite/createinvite', user);
    if (res?.data?.success && showBanner) {
      Swal.fire({
        title: "Success",
        text: "Invitation sent",
        icon: "success",
      });
      return true;
    } else {
      // throw new Error("Error occurred while inviting new user");
      return false;
    }
  } catch (e) {
    console.error(e);
    if (
      e?.response?.status >= 400 &&
      e?.response?.status <= 500 &&
      showBanner
    ) {
      Swal.fire({
        title: "Error",
        text: e.response.data?.msg,
        icon: "error",
      });
    } else if (showBanner) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
    }
    return false;
  }
};

export const requestVendor = async (user) => {
  try {
    const res = await api.post('/user/requestvcm', user);
    if (res?.data?.success) {
      Swal.fire({
        title: "Success",
        text: "Request sent",
        icon: "success",
      });
      return res;
    } else {
      // throw new Error("Error occurred while inviting new user");
      return res;
    }
  } catch (e) {
    console.error(e);
    if (e?.response?.status >= 400 && e?.response?.status <= 500) {
      Swal.fire({
        title: "Error",
        text: e.response.data?.msg,
        icon: "error",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
      });
    }
    return false;
  }
};
// export const addEmploymentDetails = async (id, data) => {
//   return api
//     .post(
//       `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles/${id}/employments`,
//       data
//     )
// };

export const updateEmploymentDetails = async (id, employmentId, data) => {
  return api
    .patch(
      `${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles/${id}/employments/${employmentId}`,
      data
    )
};

export const addEducation = async (details, edit, userId, role) => {
  try {
    const res = await api.post(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles/education${edit ? `?user_id=${userId}` : ''}${role ? `${edit ? '&' : '?'}role=${role}` : ''}`, details);
    if(res && res.data) {
      return { success: true, message: res.data?.message, data: res.data }
    } return { success: false, message: res?.data?.error }
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}

export const updateEducation = async (id, details, edit, userId) => {
  try {
    const res = await api.patch(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles/education/${id}${edit ? `?user_id=${userId}` : ''}`, details);
    if(res && res.data) {
      return { success: true, message: res.data?.message, data: res.data?.[0] }
    } return { success: false, message: res?.data?.error }
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}

export const deleteEducation = async (id, edit, userId) => {
  try {
    const res = await api.delete(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/profiles/education/${id}${edit ? `?user_id=${userId}` : ''}`);
    if (res && res.data) {
      return { success: true, message: res.data.message, data: res.data };
    }
    return { success: false, message: res.data.error };
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}

export const addExperience = async (details, edit, id, role) => {
  try {
    const res = await api.post(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/experiences${edit ? `?user_id=${id}` : ''}${role ? `${edit ? '&' : '?'}role=${role}` : ''}`, details);
    if (res && res.data) {
      return { success: true, message: res.data.message, data: res.data };
    }
    return { success: false, message: res.data.error };
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}

export const updateExperience = async (id, details, edit, userId) => {
  try {
    const { id: ids, profile_id, created_at, updated_at, ...rest } = details
    const res = await api.patch(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/experiences/${id}${edit ? `?user_id=${userId}` : ''}`, removeKeysFromObject(rest));
    if (res && res.data) {
      return { success: true, message: res.data.message, data: res.data };
    }
    return { success: false, message: res.data.error };
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}

export const deleteExperience = async (id, edit, userId) => {
  try {
    const res = await api.delete(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/experiences/${id}${edit ? `?user_id=${userId}` : ''}`);
    if (res && res.data) {
      return { success: true, message: res.data.message, data: res.data };
    }
    return { success: false, message: res.data.error };
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}

export const verifyExperience = async (details, isResourceEditing, id) => {
  try {
    const res = await api.post(`email/sendVerificationEmail${isResourceEditing ? `?user_id=${id}` : ''}`, details);
    if (res && res.data) {
      return { success: true, message: res.data.message, data: res.data };
    }
    return { success: false, message: res.data.error };
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}

export const updateVerification = async (id, details) => {
  try {
    const res = await api.patch(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/experiences/verfiyExperience/${id}`, details);
    if (res && res.data) {
      return { success: true, message: res.data.message, data: res.data };
    }
    return { success: false, message: res.data.error };
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}

export const getExperience = async (id) => {
  try {
    const res = await api.get(`${process.env.REACT_APP_SHARED_RESOURCES_URL}/api/v1/experiences/${id}`);
    if (res && res.data) {
      return { success: true, message: res.data.message, data: res.data };
    }
    return { success: false, message: res.data.error };
  } catch (e) {
    return { success: false, message: e?.response?.data?.error || 'Something went wrong!!' };
  }
}