import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Avatar, Divider } from "@mui/material";
import Button from "Components/Common/semantic_tags/Button";
import CustomInput from "Components/Common/semantic_tags/Input";
import Typography from "Components/Common/semantic_tags/Typography";
import useDebounce from "hooks/useDebounce";
import { shareJob } from "Pages/MarketplaceNew/jobapplication.api";
import { useAlertProvider } from "Providers/util/Alert";
import { useConnectionProvider } from "Providers/Vendor/Connections";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserDetails } from "Redux/Feature/user";
import { stringAvatar } from "Utils/avatar";
import { truncateText } from "Utils/helper";

const ShareJobModal = ({ details, closeModal }) => {
  const userDetails = useSelector(selectUserDetails);
  const [searchText, setSearchText] = useState("");
  const [selectedPartner, setselectedPartner] = useState([]);
  const [selectedResourceDetails, setSelectedResourceDetails] = useState([])
  const [viewResources, setViewResources] = useState(false)
  const [rowCount, setRowCount] = React.useState(0);
  const { success, error } = useAlertProvider();
  const searchTextDebounce = useDebounce(searchText, 300)
  const {  vendors, fetchCurrentVendors } = useConnectionProvider();
  const [allResources, setAllResources] = useState(vendors);
  const pagination = {
    pageSize: 100,
    pageIndex: 0
  }

  const fetchResources = async (text = '') => {
    await fetchCurrentVendors(pagination, { id: "createdAt", desc: true }, 0, setRowCount, text);
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const handleToggleResource = (resource) => {
    const isSelected = selectedPartner.includes(resource.vendor_id);
    setselectedPartner((prevSelected) =>
      isSelected
        ? prevSelected.filter((r) => r !== resource.vendor_id)
        : [...prevSelected, resource.vendor_id]
    );
  
    setSelectedResourceDetails((prevSelected) =>
      isSelected
        ? prevSelected.filter((r) => r.vendor_id !== resource.vendor_id)
        : [...prevSelected, resource]
    )
  };

  const handleSubmit = async () => {
    const request_payload = selectedPartner.map((parter, i) => {
      return {
        job_id: details.id,
        user_id: parter,
        vendor_company: userDetails?.company,
        invitation_for: 'VENDOR'
      }
    });
    const resp = await shareJob(request_payload);
    if (resp.success) {
      success(resp.message || "Job Shared Successfully");
      closeModal();
    } else error(resp.error || resp.message || "Something went wrong!!!");
  };

  useEffect(() => {
    fetchResources(searchTextDebounce);
  }, [searchTextDebounce])
  
  return (
    <div className="flex items-start gap-4 p-6 relative">
      <div className="w-[80%]">
        <h5 className="text-xl font-bold">Sharing To</h5>
        <div className="flex items-start gap-3 mt-5">
          {details?.vendorImg ? (
            <img
              alt="Profile"
              className="w-12 h-12 rounded-full object-cover"
              src={details.vendorImg}
            />
          ) : (
            <Avatar
              className="!text-xs"
              {...stringAvatar(details?.vendorName ?? "ProLegion User", {
                width: 40,
                height: 40,
              })}
            />
          )}
          <div>
            <p className="font-semibold text-gray-700">{details?.name}</p>
            <p className="text-sm text-gray-700">{details?.vendorName}</p>
          </div>
        </div>
        <div className="uniform-font" style={{ inlineSize: "100%", color: '#6B7280 !important', wordWrap: 'break-word', overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: truncateText(details?.description, 60) }} />
      </div>
      <Divider orientation='vertical' flexItem sx={{ border: 'none', minWidth: '1px', backgroundColor: 'rgba(76, 78, 100, 0.12)' }} />
      <div className="overflow-y-scroll h-[60vh] min-w-[65%]">
        <div className="mt-5 mb-5 bg-white">
          <Typography className="font-semibold text-sm text-gray-700">{ !viewResources ? 'Your Partners' : 'Selected Partners' }</Typography>
          {!viewResources && <div className="relative">
            <CustomInput
              type="text"
              placeholder="Search Partner Name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>}
        </div>
        {(!viewResources ? vendors?.filter(i => i?.vendor2?.firstName?.includes(searchTextDebounce) || i?.vendor2?.lastName?.includes(searchTextDebounce)) : selectedResourceDetails)
          .map((resource, key) => {
            return (
              <div
                key={key}
                className="mb-5 border-b pb-4"
              >
                <div className="flex gap-2 items-center">
                  {
                    resource?.profilePhoto
                      ? <img
                        src={resource?.profilePhoto}
                        alt={`${resource.firstName} ${resource.lastName}`}
                        className="w-8 h-8 rounded-full"
                      />
                      : <Avatar
                        className="!text-xs !rounded-full"
                        {...stringAvatar(resource.firstName ?? "ProLegion User", {
                          width: 50,
                          height: 50,
                        })}
                      />
                  }
                  <div className="flex flex-col">
                    <p className="font-semibold text-sm text-gray-700">
                      {resource.firstName} {resource.lastName}
                    </p>
                    <p className="text-sm text-gray-500">
                      {resource?.vendor2?.company}
                    </p>
                  </div>
                </div>
                <div className="flex items-start mt-2 pl-10">
                  <Typography className="text-xs font-bold">Skills: &nbsp;</Typography>
                  <p className="text-xs text-gray-500">
                    {resource.vendor2?.skills?.length > 0
                      ? resource.vendor2.skills.join(", ")
                      : "No Skills Added"}
                  </p>
                </div>
                <div className="flex justify-end items-center gap-4">
                  <Typography className="text-xs text-[#3985B6] cursor-pointer" onClick={() => window.open(window.location.origin + '/' + (resource?.username || ""), '_blank')}><OpenInNewIcon sx={{ fontSize: '12px' }} /> View Company Profile</Typography>
                  <div
                    className={`flex items-center gap-2 border rounded-md px-3 py-1 cursor-pointer ${selectedPartner.includes(resource.vendor_id)
                      ? "border-green-500 bg-green-100"
                      : "border-gray-300"
                      }`}
                    onClick={() => handleToggleResource(resource)}
                  >
                    {selectedPartner.includes(resource.vendor_id) ? (
                      <svg
                        className="w-5 h-5 text-green-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    ) : (
                      <input
                        type="checkbox"
                        checked={selectedPartner.includes(
                          resource.vendor_id
                        )}
                        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      />
                    )}
                    <p className="text-sm">
                      {selectedPartner.includes(resource.vendor_id)
                        ? "Selected"
                        : "Select"}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="absolute bottom-0 right-0">
        {!viewResources
          ? <Button
            onClick={() => setViewResources(true)}
            title='View Partners'
          />
          : <div className="flex items-center gap-4">
            <Button
              onClick={() => setViewResources(false)}
              className="bg-gray-500 hover:!bg-gray-500 border-gray-500"
              title='Back'
            />
            <Button
              onClick={handleSubmit}
              title='Share Job'
            />
          </div>
        }
      </div>
    </div>
  );
};

export default ShareJobModal;
