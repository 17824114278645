import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/UploadFile';
import {
  Autocomplete,
  Box,
  Card,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import JobsHome from './Jobs/JobsHome';

import MuiModal from 'Components/Common/MuiModal';
import Button from 'Components/Common/semantic_tags/Button';
import MyEditor from 'Components/TextEditor';
import useDebounce from 'hooks/useDebounce';
import { addNewJobInMarketplace } from 'Pages/MarketplaceNew/marketplace.api';
import { useAlertProvider } from 'Providers/util/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { jobsDataSelector, setJobCategories } from 'Redux/Feature/jobs';
import { selectUserDetails, selectUserRole } from 'Redux/Feature/user';
import api from 'Utils/api';
import { userPermissions } from 'Utils/constants';
import { getPermissions } from 'Utils/helper';
import Back from '../../../Components/Back';
import SimpleBackDrop from '../../../Components/SimpleBackDrop';
import { useJobProvider } from '../../../Providers/Client/Jobs';
import { useProjectProvider } from '../../../Providers/Client/Project';
import { useCountryStateCityProvider } from '../../../Providers/Common/CountyStateCity';
import BulkJobUploadModal from '../BulkJobUploadModal/BulkJobUploadModal';
import CreateJobs from './Jobs/CreateJob';
import { fetchCurrency } from './Jobs/jobs.api';
import { countLettersWithoutTags } from './Jobs/validation';

export default function Jobs({ setActive }) {
  const permissions = getPermissions();
  const url = new URLSearchParams(window.location.search)
  const { success, error } = useAlertProvider()
  const defaultJobDetails = {
    created_by: localStorage.getItem('userId'),
    projectId: '',
    name: '',
    category_id: "",
    description: '',
    country: '',
    city: '',
    state: '',
    // start_date: new Date(),
    rate: '',
    rate_type: 'HOURLY',
    primary_skill: '',
    main_skill: '',
    location: 'REMOTE',
    currency: 'USD',
    other_skills: [],
    attachment_link: '',
    status: 'Posted',
    project: '',
    isPublicalyVisible: !!(url.get('from') === 'marketplace'),
    isCheckinCheckout: false,
    isError: false,
  };

  const [jobType, setJobType] = useState('Hourly/ Contract');
  const [postJob, setPostJob] = useState(false);
  const [open, setOpen] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [jobDetails, setJobDetails] = useState(defaultJobDetails);
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const navigate = useNavigate();
  const showModal = () => setOpen(true);
  const handleOk = () => setOpen(false);
  const handleCancel = () => setOpen(false);
  const userDetails = useSelector(selectUserDetails);
  const [clients, setClients] = useState([]);
  const dispatch = useDispatch();
  const {jobCategories} = useSelector(jobsDataSelector);
  const {isClient} = useSelector(selectUserRole);
  const [searchedVal, setSearchedVal] = useState('');
  const debounceSearch = useDebounce(searchedVal)
  const [searchParams, setSearchParams] = useSearchParams();
  const getAddNewJobQuery = searchParams.get('add-new-job');
  const [jobPage, setJobPage] = useState(0);

  const wordCount = useMemo(() => {
    return countLettersWithoutTags(jobDetails.description);
  }, [jobDetails]);

  const rate = useMemo(()=>{
    return parseInt(jobDetails.rate)
  },[jobDetails.rate]);

  const isValidCreateJobForm = useMemo(()=> {
    const errors = {};
    if(wordCount === 0) {
      errors.isNotValidDescription = true;
    } if(!jobDetails.name) {
      errors.isNotValidname = true;
    } if(!jobDetails.category_id) {
      errors.isNotValidJobCategory = true;
    } if(!jobDetails.start_date) {
      errors.isNotValidStartDate = true;
    } if(!jobDetails.main_skill?.length > 0) {
      errors.isSkillEmpty = true;
    } if(!jobDetails.location) {
      errors.isNotLocation = true;
    } if(!jobDetails.rate_type) {
      errors.isNotRateType = true;
    } if(rate < 0) {
      errors.isNotRate = true;
    } if(!jobDetails.currency) {
      errors.isNotCurrency = true;
    } if(jobDetails.location === "ONSITE" || jobDetails.location === "HYBRID") {
      if(!jobDetails.country) {
        errors.isNotLocationCountry = true;
      }
    } 
    if (
      !jobDetails.experience ||
      jobDetails.experience < 1.0 ||
      !/^\d+(\.([0-9]|1[0-2]))?$/.test(jobDetails.experience)
    ) {
      errors.experience = true;
    }
    
    return errors;
  },[jobDetails]);

  // '& .MuiOutlinedInput-root': {
  //   '& fieldset': {
  //     borderColor: 'red',
  //   },

  const updateHandler = (value, name) =>
    setJobDetails({ ...jobDetails, [name]: value });

  const handleJobDetailsChange = (value, name) => {
    if (name === 'isPublicalyVisible') {
      setJobDetails({
        ...jobDetails,
        isPublicalyVisible: !jobDetails.isPublicalyVisible,
      });
    } else {
      if (value === 'Add Project') showModal();
      setJobDetails({ ...jobDetails, [name]: value || '' });
    }
  };

  const [project, setProject] = useState({
    userId: localStorage.getItem('userId'),
    projectTitle: '',
    projectDetail: '',
    client_id: {
      label: "Self Project",
      value: userDetails.user_id
    }
  });

  const { addProject, fetchProjects } = useProjectProvider();
  const { addJob, getAllSkills, fetchJobDetails, updateJob, getAllJobCategories } =
    useJobProvider();
  const addProjectWrapper = async () => {
    const data = await addProject({ ...project, client_id: project?.client_id?.value, ...( project?.client_id?.value && {project_type: 'FLEXIBLE'}) });
    if (data.success) {
      success('Project created successfully')
      setOpen(false);
      handleJobDetailsChange({ label: project.projectTitle, value: data.projectId }, 'projectId');
    } else {
      error('Something went wrong!')
      setOpen(false);
    }
  };
  const {
    getCountries,
    getStatesByCountry,
  } = useCountryStateCityProvider();

  const location = useLocation();

  useEffect(() => {
    fetchProjects();
    getAllSkills();
    getCountries();
    getStatesByCountry(jobDetails?.country);
  }, [jobDetails?.country]);

  useEffect(() => {
    if (postJob) handleJobDetailsChange('', 'projectId');
  }, [postJob]);

  useEffect(() => {
    if (jobId && postJob) {
      setLoadingContent(true)
      fetchJobDetails(jobId).then((data) => {
        const projectId = { label: data?.project?.title || 'Default Project', value: data?.project?.id || null }
        setJobDetails({ ...data, projectId, currency: data?.currency || data?.currency_type })
        setLoadingContent(false)
      });
    }
    setJobDetails((prev)=> ({...prev, category_id: jobCategories[0]?.category}))
  }, [jobId, postJob]);

  const jobLocationForAISearch = () => {
    const city = typeof jobDetails.city === "string" ? jobDetails.city : jobDetails.city?.name; 
    const state = typeof jobDetails.state === "string" ? jobDetails.state : jobDetails.state?.name; 
    const country = typeof jobDetails.country === "string" ? jobDetails.country : jobDetails.country?.name; 
    if(jobDetails.location === "REMOTE"){
      return "REMOTE";
    } else {
      return `${jobDetails.location.toLowerCase()} at ${city ? `${city},` : ""} ${state ? `${state},` : ""} ${country ? `${country}` : ""}`
    }
  };

  const handleOpenAITextGenerator = async() => {
    try {
      setLoadingContent(true);
      setJobDetails((prev) => ({...prev, description: ""}))
      // setLoading(true);
      const res = await api.post(`${process.env.REACT_APP_NLP_URL}/generate`, {
        input: jobDetails.name,
        skills: jobDetails.main_skill?.map((item)=> item?.name).join(', '),
        location : jobLocationForAISearch(),
        // rateType : jobDetails.rate_type
      }, {headers: { 'X-API-KEY': process.env.REACT_APP_NLP_X_API_KEY}});
      setJobDetails((prev) => ({...prev, description: res.data}))
      // setLoading(false);
      setLoadingContent(false);
    } catch (err) {
      console.log(err?.response);
      error("Something Went Wrong");
      setJobPage(0);
      // setLoading(false);
      setLoadingContent(false);
      return error;
    }
  }

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setJobId(id);
    }
  }, [id]);


  const fetchClients = async () => {
    const userId = localStorage.getItem('userId');
    const params = { id: userId, for: `${isClient ? "client" : "vendor"}` };
    const res = await api.get('/user/listcvmusers', { params });
    if (res.status === 200) {
      res?.data?.result?.push({
        id: userDetails?.user_id,
        company: "Self Project",
      })
      return setClients(res?.data?.result || []);
    }
    return null;
  };
  
  useEffect(() => {
    fetchClients()
    fetchCurrency().then((res) => {
      setJobDetails({ ...jobDetails, currency: res.data.currency || 'USD' })
    })
    setJobDetails((prev)=> ({...prev, category_id: jobCategories[0]?.category}))
  }, [])

  useEffect(()=> {
    getAllJobCategories().then((res)=> {
      dispatch(setJobCategories(res));
      setJobDetails((prev)=> ({...prev, category_id: jobCategories[0]?.category}))
    });
  },[]);

  const addUpdateMarketplace = async (jobDetails, chain = null) => {
    const resp = await addNewJobInMarketplace({
      currency_type: jobDetails?.currency,
      rate_type: jobDetails?.rate_type,
      name: jobDetails?.name,
      rate: jobDetails?.rate,
      location: jobDetails?.location,
      description: jobDetails?.description,
      experience: jobDetails?.experience,
      availability: jobDetails?.availability,
      status: 'OPEN',
      category_id: jobDetails?.jobCategory?.id,
      createdBy: jobDetails?.created_by,
      createrChain: chain || [...(jobDetails?.client_id ? [jobDetails.client_id] : []), ...(jobDetails?.vendor_id ? [jobDetails.vendor_id] : []), ...jobDetails?.chain],
      country: jobDetails?.country?.name || jobDetails?.country,
      state: jobDetails?.state?.name || jobDetails?.state,
      city: jobDetails?.city?.name || jobDetails?.city,
      main_skill: jobDetails.main_skill.map((i) => i?.name || i),
      primary_job_id: jobDetails.id,
      vendorName: userDetails?.company,
      vendorImg: userDetails?.profile_image,
    })
    if (!resp.success) {
      return error(resp?.message || 'Something went wrong')
    }
    await updateJob({ id: jobDetails?.id, isPublicalyVisible: true })
    success('Job posted successfully')
  } 
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const jobCategoryId = jobCategories.find((item)=> item.category === jobDetails.category_id);
    if (!jobDetails.name || wordCount === 0 || !jobDetails.main_skill?.length > 0 || rate < 0 || !jobDetails.currency || !jobDetails.description || !jobDetails.rate_type || isValidCreateJobForm?.isNotLocationCountry) {
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Error...',
      //   text: 'Please Enter All Details',
      // });
      setJobDetails((prev)=>({...prev, isError: true}))
      setLoading(false);
    } else if (
      jobId &&
      (jobDetails.projectId?.label === 'Default Project' ||
        jobDetails.projectId?.value === '')
    ) {
      setJobDetails((prev)=>({...prev, isError: false}))
      updateJob({
        ...jobDetails,
        category_id: jobCategoryId?.id,
        country: jobDetails?.country?.iso2 || jobDetails?.country,
        state: jobDetails?.state?.iso2 || jobDetails?.state,
        city: jobDetails?.city?.name || jobDetails?.city,
        main_skill: jobDetails.main_skill.map((i) => i?.name || i),
        projectId: null,
      }).then((ok) => {
        setLoading(false);
        setJobDetails(defaultJobDetails);
        setJobId(ok);
        ok && navigate(`/dashboard/jobs/${ok}`, {
          state: { from: location },
        });
        // return ok ? setJobDetails(defaultJobDetails) : null;  
      });
      setJobDetails(defaultJobDetails);
      setJobId(null);
      setPostJob(false);
    } else if (jobId) {
      setJobDetails((prev)=>({...prev, isError: false}))
      updateJob({
        ...jobDetails,
        category_id: jobCategoryId?.id,
        country: jobDetails?.country?.iso2 || jobDetails?.country,
        state: jobDetails?.state?.iso2 || jobDetails?.state,
        city: jobDetails?.city?.name || jobDetails?.city,
        main_skill: jobDetails.main_skill.map((i) => i?.name || i),
        projectId: jobDetails?.projectId?._id || jobDetails?.projectId?.value || null,
      }).then((ok) => {
        setLoading(false);
        setJobDetails(defaultJobDetails);
        setJobId(ok);
        ok && navigate(`/dashboard/jobs/${ok}`, {
          state: { from: location },
        });
        // return ok ? setJobDetails(defaultJobDetails) : null;
      });
      setJobDetails(defaultJobDetails);
      setJobId(null);
      setPostJob(false);
    } else if (
      jobDetails.projectId === 'Default Project' ||
      jobDetails.projectId === ''
    ) {
      setJobDetails((prev)=>({...prev, isError: false}))
      addJob({
        ...jobDetails,
        category_id: jobCategoryId?.id,
        country: jobDetails?.country?.iso2 || jobDetails?.country,
        state: jobDetails?.state?.iso2 || jobDetails?.state,
        city: jobDetails?.city?.name || jobDetails?.city,
        main_skill: jobDetails.main_skill.map((i) => i?.name || i),
        projectId: null,
      }).then(async (ok) => {
        setLoading(false);
        setJobDetails(defaultJobDetails);
        setJobId(ok);
        if (ok && jobDetails.isPublicalyVisible) {
          await addUpdateMarketplace({ ...jobDetails, id: ok }, [userDetails.user_id])
        }
        ok && navigate(`/dashboard/jobs/${ok}`, {
          state: { from: location },
        });
        // return ok ? setJobDetails(defaultJobDetails) : null;
      });
      setJobDetails(defaultJobDetails);
      setJobId(null);
      setPostJob(false);
    } else {
      setJobDetails((prev)=>({...prev, isError: false}))
      addJob({
        ...jobDetails,
        category_id: jobCategoryId?.id,
        country: jobDetails?.country?.iso2 || jobDetails?.country,
        state: jobDetails?.state?.iso2 || jobDetails?.state,
        city: jobDetails?.city?.name || jobDetails?.city,
        main_skill: jobDetails.main_skill.map((i) => i?.name || i),
        projectId: jobDetails?.projectId?._id || jobDetails?.projectId?.value || null,
      }).then(async (ok) => {
        setLoading(false);
        setJobDetails(defaultJobDetails);
        setJobId(ok);
        if (ok && jobDetails.isPublicalyVisible) {
          await addUpdateMarketplace({ ...jobDetails, id: ok }, [userDetails.user_id])
        }
        ok && navigate(`/dashboard/jobs/${ok}`, {
          state: { from: location },
        });
        // return ok ? setJobDetails(defaultJobDetails) : null;
      });
      setJobDetails(defaultJobDetails);
      setJobId(null);
      setPostJob(false);
    }
  };

  useEffect(() => {
    if (url.get('type') === 'create') {
      setJobType("Hourly/ Contract");
      setPostJob(true)
    }
  }, [])

  useEffect(() => {
    if(getAddNewJobQuery === 'true') {
      setPostJob(true);
      setJobType("Hourly/ Contract");
    }
  },[getAddNewJobQuery])

  const handleBackwardPage = () => {
    if(jobPage > 0) {
      setJobPage((prev) => prev - 1);
    }
  }

  return (
    <Card sx={{ padding: "15px" }}>
      <SimpleBackDrop open={loading} />
      {!postJob && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <Typography variant="cardHeader">Jobs</Typography>
          {permissions?.includes("admin-client") ||
          permissions?.includes(userPermissions.job.add) ? (
              <div className='flex gap-4 items-center'>
                <Button
                  onClick={() => setOpenImportModal(true)}
                  title={<><UploadIcon /> Upload Jobs</>}
                />
                <Button
                  onClick={() => {
                    setPostJob(true);
                    setJobPage(0);
                    setJobType("Hourly/ Contract");
                  }}
                  title={<><AddIcon /> Create Job</>}
                />

              </div>
          ) : null}
          <BulkJobUploadModal open={openImportModal} setModalOpen={setOpenImportModal}/>
        </Box>
      )}
      {!postJob ? (
        <JobsHome
          setActive={setActive}
          setJobType={setJobType}
          setPostJob={setPostJob}
          jobId={jobId}
          setSearchedVal={setSearchedVal}
          valSearch={searchedVal}
          setJobId={setJobId}
          searchedVal={debounceSearch}
          projectId={projectId}
          setProjectId={setProjectId}
          addUpdateMarketplace={addUpdateMarketplace}
        />
      ) : (
        <>
          {jobPage === 0 && <div className="flex flex-row space-x-2">
            <Back
              onClick={() => {
                if (url.get('from') === 'marketplace') navigate('/dashboard/marketplace');
                setPostJob(false);
                setJobId(null);
                setJobDetails(defaultJobDetails);
              }}
            />
            <span className="mt-0.5">Back</span>
          </div>}
          <CreateJobs
            key={`${loadingContent}`}
            wordCount={wordCount}
            setOpen={setOpen}
            jobDetails={jobDetails}
            jobId={jobId}
            isValid={isValidCreateJobForm}
            isError={jobDetails.isError}
            loading={loadingContent}
            updateHandler={updateHandler}
            handleOpenAITextGenerator={handleOpenAITextGenerator}
            jobPage={jobPage}
            setJobPage={setJobPage}
          />
          {jobPage === 1 && <Box sx={{ display: "flex", justifyContent: "flex-end", pt: "3rem" }}>
            <Button onClick={handleBackwardPage} title={"Go Back"} parentClassName='pr-4' className='w-40' />
            {permissions?.includes("admin-client") ||
            permissions?.includes(userPermissions.job.add) ? (
              <Button
                className='w-40'
                onClick={submitHandler}
                disabled={(wordCount || 0) > 3000}
                title={<>{jobId && postJob ? "Update Job" : "Create Job"}</>}
              />
            ) : null}
          </Box>}
          <MuiModal
            title="Add Project"
            open={open}
            onCancel={handleCancel}
          >
            <form className="mt-2">
              {userDetails?.role !== 'CLIENT' && <Box className="flex flex-col md:space-y-0 space-y-2 mb-4">
                <Autocomplete
                  value={project.client_id || project.client_id}
                  clearIcon={false}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value || option.value === value?.value
                  }
                  getOptionLabel={(option) =>
                    option.label
                      ? option.label
                      : option.title
                      ? option.title
                      : option
                  }
                  onChange={(_, v) => setProject({ ...project, client_id: v })}
                  options={(clients || []).map((client) => ({
                    value: client.id,
                    label: client.company,
                  }))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Client"
                      placeholder="Choose your Client"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Box>}
              <div className="flex flex-col md:space-y-0 space-y-2">
                <TextField
                  label="Project Title"
                  placeholder="Enter Project Title"
                  value={project.projectTitle}
                  onChange={(e) => {
                    setProject({
                      ...project,
                      projectTitle: e.target.value,
                    });
                  }}
                />
              </div>
              <Box sx={{ marginTop: "20px" }}>
                <Typography>Project Details</Typography>
                <MyEditor
                  html={project.projectDetail}
                  updateRaw={(e) => {
                    setProject({
                      ...project,
                      projectDetail: e,
                    });
                  }}
                  height={200}
                />
              </Box>

              <div className="mt-[50px] sm:space-x-4 sm:space-y-0 space-y-4" />
              <Button
                parentClassName='!w-full justify-end'
                onClick={(event) => {
                  event.preventDefault();
                  addProjectWrapper();
                  handleOk()
                  setProject({ projectDetail: "", projectTitle: "", client_id: '' });
                }}
                title='Create Project'
              />
            </form>
          </MuiModal>
        </>
      )}
    </Card>
  );
}
