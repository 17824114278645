export const profileTabs = (role, isEdit = false) => {
  const tabs = {
    CONSULTANT: [
      'About',
      'Skills',
      'Certifications',
      'Experience',
      'Education',
      ...(!isEdit ? ['Employment'] : [])
    ],
    VENDOR: [
      'About',
      'Services & Skills',
    ],
    CLIENT: [
      'About',
      'Services & Skills',
    ],
  };
  return tabs[role];
};

export const companySizeOptions = [
  {
    label: '1-10 employees',
    value: '1-10',
  },
  {
    label: '11-50 employees',
    value: '11-50',
  },
  {
    label: '51-200 employees',
    value: '51-200',
  },
  {
    label: '201-500 employees',
    value: '201-500',
  },
  {
    label: '501-1000 employees',
    value: '501-1000',
  },
  {
    label: '1001-5000 employees',
    value: '1001-5000',
  },
  {
    label: '5001-10,000 employees',
    value: '5001-10,000',
  },
  {
    label: '10,001+ employees',
    value: '10,001+',
  },
];

export const availabilityOptions = [
  {
    label: '< 10 Hours/Week',
    value: '10',
  },
  {
    label: '10-20 Hours/Week',
    value: '10-20',
  },
  {
    label: '20-30 Hours/Week',
    value: '20-30',
  },
  {
    label: '30-40 Hours/Week',
    value: '30-40',
  },
  {
    label: '40+ Hours/Week',
    value: '40+',
  },
];

export const newAvailabilityOptions = [
  {
    label: 'Immediate',
    value: 'Immediate',
  },
  {
    label: 'Notice Period',
    value: 'Notice Period',
    subOptions: [
      {
        label: "15 Days",
        value: "15 Days",
      },
      {
        label: "30 Days",
        value: "30 Days",
      },
      {
        label: "60 Days",
        value: "60 Days",
      },
      {
        label: "90 Days",
        value: "90 Days",
      },
    ]
  },
  {
    label: 'After',
    value: 'After',
    // subOptions: [
    //   {
    //     label: "This Month",
    //     value: "This Month",
    //   },
    //   {
    //     label: "This Week",
    //     value: "This Month",
    //     subOptions: [
    //       {
    //         label: "15 Days",
    //         value: "15 Days",
    //       },
    //       {
    //         label: "30 Days",
    //         value: "30 Days",
    //       },
    //       {
    //         label: "60 Days",
    //         value: "60 Days",
    //       },
    //       {
    //         label: "90 Days",
    //         value: "90 Days",
    //       },
    //     ]
    //   },
    // ]
  },
 ];

export const newMobileAvailabilityOptions = [
  {
    label: 'Immediate',
    value: 'Immediate',
  },
  {
    label: 'Notice Period 15 Days',
    value: 'Notice Period 15 Days',
  },
  {
    label: 'Notice Period 30 Days',
    value: 'Notice Period 30 Days',
  },
  {
    label: 'Notice Period 60 Days',
    value: 'Notice Period 60 Days',
  },
  {
    label: 'Notice Period 90 Days',
    value: 'Notice Period 90 Days',
  },
  {
    label: 'After',
    value: 'After',
  },
 ];

export const employmentOptions = [
  {
    name: 'Self Employed',
    value: 'selfEmployed',
  },
  {
    name: 'Employed',
    value: 'employed',
  },
  {
    name: 'Other',
    value: 'other',
  },

];


export const profileKeys = [
  'role',
  'profileSection',
  'employment_details',
  'certificates',
  'industry_experience',
  'availability',
  'experience',
  'public_profile_id',
  'email',
  'username',
  'created_at',
  'updated_at',
  'employments',
  'references',
  'profile_strength',
  'resources',
  'website',
  'phone',
  'organisation',
  'numberOfResources',
  'isVendorConnected',
  'isShadowUser',
  'vendorEmail',
  'selfManagedResources'
]