import {
  Box,
  Card,
  Divider,
  LinearProgress,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import JobListing from 'Pages/Jobs/Job.table';
import { userPermissions } from 'Utils/constants';
import { getPermissions } from 'Utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import MarketplaceNew from 'Pages/MarketplaceNew';
import { useSelector } from 'react-redux';
import { selectUserRole } from 'Redux/Feature/user';
import Button from 'Components/Common/semantic_tags/Button';
import ResourceList from 'Pages/MarketplaceNew/ResourceList';
import AppliedJobs from 'Pages/MarketplaceNew/AppliedJobs';
import InvitedJobs from 'Pages/MarketplaceNew/InvitedJobs';
import ShareJobListing from './ShareJobListing';

const WrapperStyled = styled('div')(({ theme, marketing }) => ({
  [theme.breakpoints.up('lg')]: {
    width: marketing ? 'calc(1280px - 32px)' : '90%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: marketing ? 'calc(960px - 32px)' : '90%',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2),
  },
  margin: '0 auto'
}));

export default function Marketplace({ marketing }) {
  const permissions = getPermissions();
  const navigate = useNavigate();
  const [hasAccessToJobs, setHasAccessToJobs] = useState(true);
  const { isClient, isVendor, isConsultant } = useSelector(selectUserRole)
  const searchParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(
    hasAccessToJobs ? 'Jobs' : 'Consultants'
  );

  useEffect(() => {
    const hasJobPermissions =
      permissions?.includes('admin-vendor') ||
      [
        userPermissions.job.view,
        userPermissions.job.add,
        userPermissions.job.edit,
      ].some((permission) => permissions.includes(permission));

    setHasAccessToJobs(hasJobPermissions);

    if (!hasJobPermissions && !marketing) {
      setFilter('Consultants');
    }
  }, [permissions]);

  const location = useLocation();

  const tabComponents = {
    ...((!isClient || marketing) && {Jobs: <MarketplaceNew loading={loading} setLoading={setLoading} />}),
    ...((isClient || isVendor) && { 'My Jobs': <Box sx={{ marginTop: '20px' }}><JobListing /></Box> }),
    ...(isVendor && { 'Resources': <Box sx={{ marginTop: '20px' }}><ResourceList isInvite /></Box> }),
    ...(isClient && { 'Vendors': <Box sx={{ marginTop: '20px' }}><ResourceList /></Box> }),
    ...(isConsultant && {'Applied Jobs': <AppliedJobs />}),
    ...(isConsultant && {'Invited Jobs': <InvitedJobs />}),
    ...(isVendor && {'Shared Jobs': <ShareJobListing />}),
  };

  useEffect(() => {
    setFilter(searchParams.get("tab") || ((!isClient || marketing) ? 'Jobs' : 'My Jobs'));
  }, [searchParams.get("tab")]);

  useEffect(() => {
    if (location?.state?.tab === 'My Jobs') setFilter('My Jobs');
  }, [location?.state?.tab]);

  return (
    <Card>
      {loading && <LinearProgress />}
      <WrapperStyled marketing={marketing}>
        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} marginTop={'20px'}>
          {!marketing && <Stack>
            <Typography variant='subtitle1Bold'>Marketplace</Typography>
            <Typography variant='body2Normal'>
              Find Jobs and Resources, Connecting talent with opportunities
              effortlessly
            </Typography>
          </Stack>}
          {!marketing && <Box>
            {
              permissions?.includes("admin-client") ||
              permissions?.includes(userPermissions.job.add) &&
              <Button onClick={() => navigate('/dashboard/jobs?type=create&from=marketplace')}
                title={<><AddIcon />Post Job</>}
              />
            }
          </Box>}
        </Stack>
        <Box sx={{ marginTop: '20px' }}>
          {!marketing && <Box>
            <Tabs
              value={filter}
              sx={{ width: '80%', border: 'unset', overflow: 'scroll' }}
              onChange={(e, value) => navigate(`?tab=${value}`)}
            >
              {Object.keys(tabComponents).map((tab) => {
                if (tab === 'My Jobs') {
                  return (
                    <Tab
                      key={tab}
                      sx={{
                        borderBottom: '1px solid #E5E7EB',
                      }}
                      value={tab}
                      label={(
                        <>
                          <Divider
                            orientation="vertical"
                            sx={{ minWidth: '2px', marginLeft: '10px' }}
                            flexItem
                          />
                          {tab}
                        </>
                      )}
                    />
                  );
                }
                return (
                  <Tab
                    key={tab}
                    sx={{ borderBottom: '1px solid #E5E7EB', width: '30%' }}
                    value={tab}
                    label={tab}
                  />
                );
              })}
            </Tabs>
          </Box>}
        </Box>
        <div className='pt-4'>{tabComponents[filter]}</div>
      </WrapperStyled>
    </Card>
  );
}
