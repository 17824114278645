import MuiModal from "Components/Common/MuiModal";
import Button from "Components/Common/semantic_tags/Button";
import moment from "moment";
import { fetchInvitedJobs, postJobInMarketplaces } from "Pages/MarketplaceNew/jobapplication.api";
import { sharedJobsHeaders } from "Pages/MarketplaceNew/utils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserDetails } from "Redux/Feature/user";
import SubmitProposal from "./SubmitProposal";
import { useAlertProvider } from "Providers/util/Alert";

function ShareJobListing() {
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserDetails);
  const { success, error } = useAlertProvider()
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null)

  const closeModal = () => setOpenSubmitModal(false)
  const openModal = () => setOpenSubmitModal(true)

  const fetchDetails = async (id) => {
    const resp = await fetchInvitedJobs(id, `&invitation_for=VENDOR`);
    setJobs(resp.data);
  };

  useEffect(() => {
    if (userDetails && userDetails?.user_id) {
      fetchDetails(userDetails?.user_id);
    }
  }, [userDetails]);

  const renderInvitedJobHeaders = (item, index) => (
    <p className="text-sm w-1/3" key={index}>{item}</p>
  )

  const postJobInMarketplace = async (jobDetails) => {
    const data = {
      createdBy: userDetails?.user_id,
      vendorName: userDetails?.company,
      vendorImg: userDetails?.profile_image,
      job_id: jobDetails.id
    }
    const res = await postJobInMarketplaces(data)
    if (res && res.success) {
      success('Posted Successfully')
      await fetchDetails(userDetails?.user_id);
      return
    }
    return error(res.message)
  }

  return (
    <div>
      <div className="bg-gray-100 p-2.5 mb-5 rounded-md">
        <div className="flex justify-between items-center">
          {sharedJobsHeaders.map(renderInvitedJobHeaders)}
        </div>
      </div>
      <div className="p-2.5 mb-5 rounded-md">
        {jobs &&
          jobs.filter(i => i.status === 'pending').map((job) => (
            <div
              key={job.id}
              className="border-b border-gray-300 pb-2.5 mb-2.5 w-full"
            >
              <div className="flex justify-between items-center">
                <div className="text-xs w-1/3">
                  <strong>{job?.Job?.name}</strong>
                  <br />
                  <span className="text-normal mt-2">
                    Posted on {moment(job?.created_at).format("DD MMM, YYYY")}
                  </span>
                </div>
                <div className="w-1/3">
                  <div className="font-bold text-sm flex items-center gap-1">
                    {job?.vendor_company?.toUpperCase()}
                  </div>
                </div>
                <div className="flex gap-2 items-center w-1/3">
                  <Button
                    title="Submit Applicant"
                    className="bg-gray-500 hover:!bg-gray-500 border-gray-500"
                    onClick={() => (setSelectedJob(job?.Job),openModal())}
                  />
                  <Button
                    title="Post Job In Marketplace"
                    className="border-[##3985B6]"
                    onClick={() => postJobInMarketplace(job?.Job)}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      <MuiModal open={openSubmitModal} onCancel={closeModal} className="max-w-5xl">
        <SubmitProposal details={selectedJob} closeModal={closeModal} />
      </MuiModal>
    </div>
  );
}

export default ShareJobListing;
