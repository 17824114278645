import React, { useMemo, useState } from 'react';
import {
  Typography,
  Stack,
  Grid,
  useTheme,
  useMediaQuery,
  Box,
} from '@mui/material';
import { HomePageBlogPosts } from 'Components/ContentBlocks';
import { useNavigate } from 'react-router-dom';
import { FullWidthWrapper } from 'Components/Common';
import { FAQSection } from './FAQSection';
import { UserTypeTabs } from './UserTypeTabs';
import { homepageRoles, homepageToggleRolesData } from '../../../Utils/homepage';
import Button from 'Components/Common/semantic_tags/Button';

const unleashImg = 'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/unleash.png';

export function HomePage() {
  const [selectedTab, setSelectedTab] = useState('client');
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const selectedTabIndex = useMemo(() => {
    const index = homepageRoles.indexOf(selectedTab);
    return index !== -1 ? index : -1;
  }, [selectedTab])

  return (
    <>
      <FullWidthWrapper>
        <Grid>
          <Grid item xs={16} md={12} pt={isSmallScreen ? 10 : 20}>
            <Stack alignItems="center">
              <Typography textAlign="center" variant={isSmallScreen ? 'h4Bold' : 'h1Second'} color="#003B54">
                Discover Next-Gen
                <br />
                Workforce Management
              </Typography>
              <Typography textAlign="center" className='font-secondary' variantMapping={{body1: "p"}} color="#003B54" sx={{ pt: 1, pb: 4 }}>
                Boost the efficiency of your IT workforce with ProLegion- a simplified
                <br />
                {' '}
                and unified platform that enables a seamless experience.
              </Typography>
              <Button title='Try ProLegion' className='z-[999]' onClick={() => navigate('/signin')} />
              <img src={'https://prolegion-assets.s3.ap-south-1.amazonaws.com/Assets/dashboard.png'} alt="ProLegion Vendor Dashboard" style={{ marginTop: isSmallScreen ? 0 : '-80px' }} />
            </Stack>
          </Grid>
          <Grid item xs={16} md={12} pt={20}>
            <Stack gap={2} alignItems="center">
              <Typography
                textAlign="center"
                className='font-secondary'
                variant={isSmallScreen ? 'h4Bold' : 'h2'}
                sx={{
                  fontWeight: 600,
                  fontSize: isSmallScreen ? '20px' : '34px',
                  lineHeight: isSmallScreen ? '40px' : '56px',
                }}
                color="#003B54"
              >
                Empower every stage of the consulting
                <br />
                business lifecycle with ProLegion
              </Typography>
              <Typography
                textAlign="center"
                variantMapping={{body1: "p"}}
                className='font-secondary'
                color="#6B7280"
              >
                Unlock a centralized hub where job creation, client interactions, and growth
                <br />
                possibilities seamlessly merge, offering a streamlined path.
              </Typography>
            </Stack>
          </Grid>
          <UserTypeTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </Grid>
      </FullWidthWrapper>
      <Grid
        container
        sx={{
          mt: 12,
          mb: 24,
          background: `#F6F5FF url(${unleashImg}) no-repeat`,
          backgroundPositionX: 'right',
          backgroundPositionY: isSmallScreen ? 'bottom' : 'center',
          backgroundSize: 'contain',
        }}
      >
        <FullWidthWrapper>
          <Grid item xs={16} md={8}>
            <Stack
              gap={2}
              alignItems="center"
              justifyContent="center"
              sx={{
                pl: 5,
                py: '12vw',
              }}
            >
              <Typography
                variant={isSmallScreen ? 'h4Bold' : 'h2'}
                color="#181D50"
                sx={{
                  fontSize: '48px',
                  fontWeight: 700,
                  letterSpacing: '-1.5px',
                  lineHeight: '56px',
                }}
              >
                Vendor management system- driven by the cloud, motivated by efficiency
              </Typography>
              <Typography variant="body1Normal" color="#6B7280">
                Unlock advanced capabilities and insights by migrating your  workforce to ProLegion.
              </Typography>
            </Stack>
          </Grid>
        </FullWidthWrapper>
      </Grid>
      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        bgcolor="#182743"
        color="white"
        pt={4}
      >
        <Grid item xs={16} md={12}>
          <Stack
            gap={2}
            textAlign="center"
            alignItems="center"
          >
            <Typography variant={isSmallScreen ? 'h4Bold' : 'h1'}>
              {homepageToggleRolesData[selectedTabIndex].title}
            </Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant="body1Normal">
                {homepageToggleRolesData[selectedTabIndex].subtitle1}
              </Typography>
              <Typography variant="body1Normal">
                {homepageToggleRolesData[selectedTabIndex].subtitle2}
              </Typography>
            </Box>
            <Button onClick={() => navigate('/signin')} title='Try ProLegion' className='text-xs !text-[black] bg-[#fff]' />
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          <img src={homepageToggleRolesData[selectedTabIndex].img} alt="Get Started" />
        </Grid>
      </Grid>
      <FullWidthWrapper>
        <Grid container my={12}>
          <Grid item xs={16}>
            <Stack gap={2} alignItems="center" width="100%" mb={4}>
              <Typography textAlign="center" variant={isSmallScreen ? 'h4Bold' : 'h1'}>
                Our Blog
              </Typography>
              <Typography variant="body1Normal" textAlign="center" color="#6B7280">
                While you are at it, do check out our team-curated blog posts!
              </Typography>
            </Stack>
            <HomePageBlogPosts />
          </Grid>
        </Grid>
      </FullWidthWrapper>
      <FAQSection isSmallScreen={isSmallScreen} />
    </>
  );
}
