export const locationOptions = [
  {
    value: "REMOTE",
    label: "REMOTE",
  },
  {
    value: "ONSITE",
    label: "ONSITE",
  },
  {
    value: "HYBRID",
    label: "HYBRID",
  },
];

export const availabilityOptions = [
  { value: "Immediate", label: "Immediate" },
  { value: '0-15', label: '0-15 days' },
  { value: '15-30', label: '15-30 days' },
  { value: '30-60', label: '30-60 days' },
  { value: '60+', label: '60+ days' },
];

export const rateTypeOptions = [
  {
    value: "HOURLY",
    label: "HOURLY",
  },
  {
    value: "MONTHLY",
    label: "MONTHLY",
  },
];
export const currencyTypeOptions = [
  {
    value: "INR",
    label: "INR",
  },
  {
    value: "USD",
    label: "USD",
  },
];
